import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer.component';
import Header from '../../components/Header/Header.component';
import Hero from '../../components/Home/Hero.component';
import ClientelleOfferSection from '../../components/Layout/ClientelleOfferSection.component';
import StudyAtDreamUniversityWidget from '../../components/StudyAtDreamUniveristyWidget/StudyAtDreamUniversityWidget';
import { programs, popular, testimonials, gettingStarted } from '../../data/programs';
import { aboutUsCareerIns } from '../../data/aboutus-content';
import HeroAbout from '../../components/Home/HeroAbout.component';
import HeroFair from '../../components/Home/HeroFair.component';
import { fairActivities } from '../../data/fair';
import Title from '../../components/Header/Title.component';

const OpuviaFair = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 200) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);



    return (

        <div className="relative ">
            <Header scrolled={scrolled} />
            <Title description='OPUVIA FAIR is an international educational and career event, that brings a unique opportunity for leading education and career service providers, top employers and recruiters to showcase their educational and employment opportunities to students and job seekers. The event brings together all the stakeholders of the education and career industry for them to showcase, network and create meaningful partnerships.' title='Career Fair OPUVIA' />
            <Header scrolled={scrolled} />            <HeroFair />

            <div className=" text-black bg-blue relative">
                <section className="py-10 px-6 2xl:px-60">
                    <div className="font-bold text-2xl">
                        <h2>OPUVIA EDUCATION AND CAREER FAIR</h2>
                        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                    </div>
                    <p className="mb-2 md:flex" >
                        <div className='space-y-4'>
                            <div>
                                OPUVIA FAIR is an international educational and career event, that brings a unique opportunity for leading education and career service providers, top employers and recruiters to showcase their educational and employment opportunities to students and job seekers. The event brings together all the stakeholders of the education and career industry for them to showcase, network and create meaningful partnerships.
                            </div>
                        </div>
                    </p>


                </section>
                <section className=' py-10 px-6 2xl:px-64 space-y-4'>
                    <div className='md:flex h-full gap-x-8 '>
                        <img className='md:w-1/2 rounded-md' src="https://images.pexels.com/photos/3894378/pexels-photo-3894378.jpeg?cs=srgb&dl=pexels-picha-stock-3894378.jpg&fm=jpg" alt={"people looking at a plan together"} />
                        <div className='flex flex-col items-center justify-center gap-y-10 h-100 '>
                            <div>
                                <div className="font-bold text-2xl">
                                    <h2>Where And How Does It Hold?</h2>
                                    <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                                </div>
                                <div>
                                    OPUVIA FAIR holds in any chosen city or cities of the country usually in the months of August and September. It is marked with Exhibitions and One-on-one career consultations by top local and international Universities.
                                </div>
                            </div>
                            <div>
                                <div className="font-bold text-2xl">
                                    <h2>Vision of OPUVIA Fair</h2>
                                    <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                                </div>
                                <div>
                                    The vision is to organize it from country to country and will popularly go by the name Opuvia Fair-Cameroon Tour, Opuvia Fair-South Africa Tour, Etc.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className=' py-10 px-6 2xl:px-64 space-y-4'>
                    <div className='md:flex h-full gap-x-8 space-y-6 '>
                        <div className='flex flex-col  justify-center gap-y-10 h-100 w-full '>
                            <div>
                                <div className="font-bold text-2xl">
                                    <h2>Why Attend</h2>
                                    <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                                </div>
                                <div>
                                    <li>Learn about the universities and colleges in your locality and the world at large.</li>
                                    <li>You will navigate on-demand skills of the 21st century workplace.</li>
                                    <li> Get answers to your career questions</li>
                                    <li>  Gain new inspiration to your different career choices.</li>
                                    <li>   Participate in career workshop that will prepare you for work life.</li>
                                    <li>   Establish business relations and professional network that will help in your job search and eventually secure a job.</li>
                                    <li>   Learn about new career fields.</li>
                                    <li>   Free professional photo for your LinkedIn profile</li>
                                    <li> You gain personal access with career experts and coaches, thereby unlocking your career doors and gaining more insights on it.</li>
                                </div>
                            </div>
                        </div>
                        <img className='md:w-1/2 rounded-md' src="https://img-cdn.inc.com/image/upload/w_1024,h_576,c_fill/images/panoramic/GettyImages-1192759699_527639_nfs4lw.jpg" alt="" />
                    </div>
                </section>
                <section className=' py-10 px-6 2xl:px-64 space-y-4'>
                    <div className='md:flex h-full space-y-6 gap-x-8'>
                        <img className='md:w-1/2 rounded-md' src="https://images.pexels.com/photos/936043/pexels-photo-936043.jpeg" alt="" />
                        <div className='flex flex-col  justify-center gap-y-10 h-100 w-full '>
                            <div>
                                <div className="font-bold text-2xl">
                                    <h2>WHO CAN ATTEND</h2>
                                    <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                                </div>
                                <div>
                                    <li>  High school graduates </li>
                                    <li>Undergraduate students</li>
                                    <li>Post graduate/Master students</li>
                                    <li>Diploma holders and Job seekers</li>
                                    <li>Educational professionals, teachers, coaches</li>
                                    <li>Representatives of Colleges and Universities.</li>
                                    <li>General public</li>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="training" className=" bg-gray-50 py-10 px-6 2xl:px-64 space-y-4">
                    <div className="font-bold text-2xl uppercase">
                        <h2>Activities</h2>
                        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                    </div>
                    <div className="md:p-6">
                        <div className=" block   md:grid md:grid-cols-2 lg:grid-cols-4 gap-3 mt-5">
                            {fairActivities.map((fair, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="mb-10 bg-white rounded-md border-gray-200 border"
                                    >
                                        <img
                                            className="h-[300px] rounded-t-md w-full object-cover"
                                            src={fair.image}
                                            alt={fair.title + " (OPUVIA)"}
                                        />
                                        <div className="p-3 pb-4 ">
                                            <h2 className="font-medium text-md my-2">{fair.title}</h2>
                                            <div className="md: justify-between  md:flex flex-col">
                                                {fair.description && <p className="text-gray-600 text-sm">
                                                    {fair.description.length > 325
                                                        ? fair.description.substring(0, 330) + "..."
                                                        : fair.description}
                                                    {/* {program.description} */}
                                                </p>}
                                                {fair.url && <button className="w-[100px] p-1 rounded-md mt-3 text-white bg-opuviaBlue-500">
                                                    Read More
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>

                <section className=' py-10 px-6 2xl:px-64 space-y-4'>
                    <div className='flex h-full gap-x-8'>
                        {/* <img className='w-1/2 rounded-md' src="https://images.pexels.com/photos/936043/pexels-photo-936043.jpeg" alt="" /> */}
                        <div className='flex flex-col  justify-center gap-y-10 h-100 w-full '>
                            <div>
                                <div className="font-bold text-2xl">
                                    <h2>EVENT BOOKING FOR ATTENDEES
                                    </h2>
                                    <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                                </div>
                                <b className="font-semibold italic text-gray-600">
                                    All ACTIVITIES IN THE OPUVIA FAIR- CAMEROON TOUR ARE FREE FOR ALL ATTENDEES!!
                                </b>
                            </div>
                            <p>
                                We provide visa support letters, hotel reservations etc., for attendees who need travel and accommodation assistance. Due to the limited number of spots for participation in our career workshops, we urge interested participants to reserve their spots early enough as they will be sent career workshop material prior to the Opuvia fair.

                            </p>
                            <p>
                                For more information or inquiry, <br />
                                Email: <a href="mailto:info@opuvia.org" className="link"> info@opuvia.org</a>  <br />
                                Tel: <a href="tel:(+237) 678 968 442" className="link">(+237) 678 968 442</a><a href="tel:(+237)659 378 678" className="link">/659 378 678 </a> <br />
                                Web: <a href="https://opuvia.org" className="link">www.opuvia.org</a>  <br />

                            </p>
                            <p>You can also visit our any of our social media pages.</p>
                        </div>
                    </div>
                </section>




                <div className="h-20"></div>
            </div >
            <Footer />
        </div >
    )
}

export default OpuviaFair