import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

const Login = () => {

    const location = useLocation()


    useEffect(() => {
        const a = document.createElement("a")
        a.href = "https://app.opuvia.org/login"
        a.click();

    }, [location.pathname])
    return (
        <div>

        </div>
    )
}

export default Login