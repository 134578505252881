import React from "react";

interface Props {
  popupIcon?: React.ReactNode;
  popupTitle: string;
  popupDescription: string;
  popupActionButton: React.ReactNode[];
  popupWidth: string | number;
}

export default function ConfirmationModal({
  popupIcon,
  popupTitle,
  popupActionButton,
  popupDescription,
  popupWidth,
}: Props) {
  return (
    <div className="fixed top-0 left-0 bg-[rgba(0,0,0,0.25)] h-screen w-full flex items-center justify-center">
      <div
        className="p-[30px] bg-white shadow-[0px_4px_10px_0px_rgba(60,106,172,0.16)] rounded-md border border-[#E4E8F0]"
        style={{ width: popupWidth }}
      >
        {popupIcon && (
          <div className="mb-[15px] w-[60px] h-[60px] flex items-center justify-center mx-auto">
            {popupIcon}
          </div>
        )}

        <h2 className="text-black font-merriweather text-xl font-bold text-center mb-3">
          {popupTitle}
        </h2>
        <p className="text-[#363636] font-lato font-medium mb-4 text-center">
          {popupDescription}
        </p>
        <div className="w-full flex items-center gap-[18px]">
          {popupActionButton.map((btn, index) => {
            return btn;
          })}
        </div>
      </div>
    </div>
  );
}
