import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

interface NavigationLink {
    to: string;
    label: string;
    subLinks?: NavigationLink[];
}

interface NavigationLinksProps {
    links: NavigationLink[];
    isMobile?: boolean;
    toggleMenu?: () => void;
}

const NavigationLinks: React.FC<NavigationLinksProps> = ({ links, isMobile, toggleMenu }) => {
    const [openSubLinks, setOpenSubLinks] = useState<{ [key: string]: boolean }>({});

    const handleSubLinkToggle = (label: string) => {
        setOpenSubLinks((prev) => ({ ...prev, [label]: !prev[label] }));
    };

    return (
        <ul className={`flex ${isMobile ? "flex-col items-start" : "items-center justify-between"}`}>
            {links.map((link) => (
                <li key={link.to} className={`p-4 ${isMobile ? "mb-5" : "col-span-1"}`}>
                    <div className={`flex items-center text-white ${link.subLinks ? "cursor-pointer" : ""}`} onClick={() => link.subLinks && handleSubLinkToggle(link.label)}>
                        <NavLink to={link.to} className={({ isActive }) => `${isActive && (link?.subLinks?.length || 0) <= 0 ? "font-bold text-orange-500" : "font-normal"} ${isMobile ? "text-xl" : ""}`} onClick={() => {
                            (link?.subLinks?.length || 0) <= 0 && toggleMenu && toggleMenu()
                        }}>
                            {link.label}
                        </NavLink>
                        {link.subLinks && (openSubLinks[link.label] ? <FaAngleUp /> : <FaAngleDown />)}
                    </div>
                    {link.subLinks && openSubLinks[link.label] && (
                        <ul className={`ml-4 ${isMobile ? "flex-col items-start" : "absolute bg-opuviaBlue-500 bg-opacity-90"}`}>
                            {link.subLinks.map((subLink) => (
                                <li key={subLink.to} className="py-3 px-4 hover:bg-opacity-100 text-white">
                                    <NavLink to={subLink.to} className={({ isActive }) => (isActive ? "font-bold text-orange-500" : "font-normal")} onClick={toggleMenu}>
                                        {subLink.label}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default NavigationLinks;
