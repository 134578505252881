import image from '../assets/images/onboard.png'
import slide1 from '../assets/images/slide1.png'
import slide2 from '../assets/images/slide2.png'
import slide3 from '../assets/images/slide3.png'
import slide4 from '../assets/images/slide4.png'
import testimonials1 from '../assets/images/testimonials1.png'


export const programs = [
    {
        title: "Career Bridge",
        image: "https://media.istockphoto.com/id/1311107708/photo/focused-cute-stylish-african-american-female-student-with-afro-dreadlocks-studying-remotely.jpg?b=1&s=612x612&w=0&k=20&c=fpQZIyrCZorMYI4DoEP6opgP0IzXnlEFVlK3lOWIWEM=",
        description: `Our work career bridge/short courses target skills deficiency gaps in the traditional
        curriculum and emphasizes on the development of hard and soft skills needed in today’s
        work environment. OPUVIA enables you to quickly build work ready skills required by
        employers in this 21st century and complete certificates that will prepare you to land your
        dream career and job.`,
        url: '/'
    },
    {
        title: "Live Bootcamps",
        image: "https://bristolcreativeindustries.com/app/uploads/2021/02/Black-Codher.png",
        description: `Our global live bootcamps inspired by research from current market demands provide you
        with the unique opportunity to develop the essentials skills which will set you on a go for
        your next job, promotion or performance improvement. Our facilitators are the finest in the
        industry, who understand, teach and inspire on ways to navigate the waters in this new and
        fast changing world for your career success.`,
        url: '/'
    },
    {
        title: "Apprenticeships",
        image: "https://careerfoundry.com/en/wp-content/uploads/2021/07/A_good_coding_bootcamp_teaches_you_not_just_to_learn_code_but_how_to_talk_about_it.jpg",
        description: `OPUVIA Apprenticeship Programmes focus on closing the gap between classroom and
        applied learning creating a mutually beneficial system for students and employers in
        evolving 21 st century career fields.  Students get meaningful work experience, get paid
        college credits and industry certifications.`,
        url: '/'
    },
    {
        title: "Short Courses",
        image: "https://images.pexels.com/photos/5905713/pexels-photo-5905713.jpeg?cs=srgb&dl=pexels-katerina-holmes-5905713.jpg&fm=jpg",
        description: `We offer Bachelor, Masters and MBA degree Programmes in partnership with the
        International University of Applied Sciences (IU University) in Germany across different
        fields. You can choose to study at our dedicated online campus, The Opuvia Learning
        Centers in Cameroon or at The IU University campus in Germany with Degrees certificates
        awarded by IU University of Applied Sciences or with LONDON SOUTH BANK UNIVERSITY
        (LSBU).`,
        url: "/"
    }

]

export const popular = [
    {
        title: "Entrepreneurship Course",
        image: "https://usa.bootcampcdn.com/wp-content/uploads/sites/106/2021/05/tes_dta_blog_image_11.jpg",
        description: "Ready to bridge the gap between education and employment? Apply now to Opuvia's Career Bridge program.",
        url: '/'
    },
    {
        title: "Career Orientation",
        image: "https://media.istockphoto.com/id/1165078466/photo/african-american-businessman-extending-hand-to-shake.jpg?s=612x612&w=0&k=20&c=kF9FRorDoEJ9PtJy53S0FmFwTJFVrSoA_ZTqedG0-QA=",
        description: "Upgrade your skills and boost your career! Join Opuvia's live bootcamps today.",
        url: '/'
    },
    {
        title: "Capacity Building",
        image: "https://www.experian.com/blogs/news/wp-content/uploads/2021/09/GettyImages-1215027900.jpg",
        description: "Take your career to the next level with Opuvia's apprenticeship program. Apply today!",
        url: '/'
    },
    {
        title: "Marketing Short Course",
        image: "https://img-cdn.inc.com/image/upload/w_1024,h_576,c_fill/images/panoramic/GettyImages-1192759699_527639_nfs4lw.jpg",
        description: "Enhance your skills and expand your knowledge with Opuvia's short courses. Enroll now!",
        url: "/"
    }

]


export const gettingStarted = [
    {

        image: "https://cdn2.psychologytoday.com/assets/styles/manual_crop_1_91_1_1528x800/public/field_blog_entry_images/2018-09/shutterstock_648907024.jpg?itok=7lrLYx-B",
        description: "Ready to take the next step in your career? Let Opuvia guide you to success. Admissions into universities both locally and internationally made easy",
        url: '/',
        buttonText: "Talent Registration"
    },
    {

        image: "https://media.hswstatic.com/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL3BsYXlcLzBiN2Y0ZTliLWY1OWMtNDAyNC05ZjA2LWIzZGMxMjg1MGFiNy0xOTIwLTEwODAuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo4Mjh9LCJ0b0Zvcm1hdCI6ImF2aWYifX0=",
        description: "Attract, retain, and develop top talent with Opuvia. Our team of experts provides customized talent management solutions to help your organization succeed.",
        url: '/',
        buttonText: "Educators Registration"

    },
    {

        image: "https://spunout.ie/wp-content/uploads/2021/01/portrait-black-young-man-face-man-person-ethnic-student-diversity-diverse-confident-millennial_t20_K6aZOV-2.jpg",
        description: "Partner with Opuvia to provide our students or team with the tools and resources they need to succeed. Our career coaching and skills training programs are designed to help individuals reach their full potential.",
        url: '/',
        buttonText: "Coach Registration"

    },
    {

        image: slide2,
        description: "Partner with Opuvia to access a vetted pool of qualified candidates, build a pipeline of skilled and engaged employees. Talent recruitment made easy.",
        url: '/',
        buttonText: "Employers Registration"

    },

]

export const benefits = [
    {

        image: slide1,
        description: `Students: We prepare students for rewarding careers through our employer-preferred training programs while availing you with required career tools and placing you into higher institutions both locally and internationally.
        Job-seekers: We make you employment-ready by closing your skill gap through effective career insertion trainings, career services and showcasing.  
        Professionals: We help you advance or transition your career with our premium career development tools and premium training programmes. `,
        url: '/',
        buttonText: "Register",
        type: 'talents'
    },
    {

        image: slide2,
        description: `Companies: We bring you access to the best talents and help power your existing talents to your desired capacity, skill level and competence. 
        Governments: We assist in achieving their goal of providing quality education and Proper human capital development of her citizens. 
        NGO’s: We are your strategic partner in bringing access to quality education as well as human capital development in Africa and the world at large.
        `,
        url: '/',
        buttonText: "Learn More",
        type: "employers"
    },
    {

        image: slide3,
        description: `Universities: We accompany you in ensuring that students are adequately prepared to be work-ready through our career bridging programmes and services. We also serve as you student recruitment agency
        Secondary Schools: We support your students with the right career guidance with our career services and provide them with access to quality higher education.
        Vocational training institutions: We are your ideal partner in showcasing your talents and availing them with the needed career services and career success skills. 
        `,
        url: '/',
        buttonText: "Learn More",
        type: "educators"
    },
    {

        image: slide4,
        description: `Industry Experts: Coach one or more of our trainees in your subject matter area during their training in Opuvia and beyond as an Opuvia Talent Influencer. 
        HR Experts: We believe in hand holding and passing on. Opuvia occasions a safe space for effective mentorship and positions of younger talents by you. 
        Career Counselors: We create the perfect space and avail you with the right tools for effective guidance counselling and path-finding for talents. 
        `,
        url: '/',
        buttonText: "Learn More",
        type: "coaches"
    },
]


export const testimonials = [
    {
        name: "TADBET AVERRA from CRIZAC",
        image: "https://i.fbcd.co/products/resized/resized-750-500/d4c961732ba6ec52c0bbde63c9cb9e5dd6593826ee788080599f68920224e27d.jpg",
        message: "I was a new graduate with the issues of obtaining a job because of poor CV and Motivation Letter. Attending the OPUVIA FAIR as a volunteer in 2019 brought the change I had always desired in my profession. Within the shortest time, I could write a winning CV, Motivation Letter, as well as validate my first job interview at a popular marketing firm. Thanks to the OPUVIA FAIR, I had a smooth school to work transition."
    },
    {
        name: `Gaston KWA,DTM
        Division  Director Toastmasters in Central Africa(P)
        `,
        image: testimonials1,
        message: "Participating in the Opuvia Fair enabled me and other Toastmasters  to have insightful exchanges with  students, parents, and other education stakeholders on the critical role soft skills play  in life and career success. This fair is a huge networking and skills building event for every education stakeholder."
    },

    // {
    //     name: "Bob Johnson",
    //     image: "https://images.pexels.com/photos/2914149/pexels-photo-2914149.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    //     message: "I love Opuvia's holistic approach to addressing the problem of graduate under and unemployment. They are truly making a difference in the lives of job seekers and employers alike."
    // },

]