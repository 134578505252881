import { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/home/Home.screen";
import { IonApp, IonContent, setupIonicReact } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import CareerCounceling from "./pages/CareerCounselling/CareerCounselling";
import InterviewPrep from "./pages/InterviewPrep/InterviewPrep";
import About from "./pages/home/About.screen";
import OpuviaFair from "./pages/home/OpuviaFair.screen";
import PartnerWithUs from "./pages/home/PartnerWithUs.screen";
import Login from "./pages/Redirects/Login";
import Signup from "./pages/Redirects/Signup";
import UniPlacement from "./pages/Redirects/UniPlacements";
import NotFound from "./pages/home/NotFound";
import CareerInstitute from "./pages/home/CareerInstitute";

setupIonicReact();

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;

function AppRoutes() {

  useEffect(() => {

    window.scrollTo({ top: 0, behavior: "smooth" })

  }, [window.location.pathname])


  return (
    <>
      <IonApp>
        <IonContent id="main-app-content" >
          <Routes>
            <Route path="/about" Component={About} />
            <Route path="/career/career-counselling" Component={CareerCounceling} />
            <Route path="/career/interview-preparation" Component={InterviewPrep} />
            <Route path="/career/institute" Component={CareerInstitute} />
            <Route path="/opuvia-fair" Component={OpuviaFair} />
            <Route path="/home" Component={Home} />
            <Route path="/signup" Component={Signup} />
            <Route path="/login" Component={Login} />
            <Route path="/" Component={Home} />
            <Route path="/partner-with-us" Component={PartnerWithUs} />
            <Route path="/uni-placement" Component={UniPlacement} />
            <Route path="*" Component={NotFound} />
          </Routes>
        </IonContent>
      </IonApp>
    </>
  );
}
