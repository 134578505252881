
export interface TeamMember {
    name: string;
    image_url: string;
    email: string;
    short_description: string;
    long_description: string;
    position: string;
}

export const teamMembers: TeamMember[] = [
    {
        name: "Ewang Atabe",
        image_url: "/team/ewang_team.jpeg",
        email: "ewang.atabe@opuvia.org",
        short_description: `Ewang Atabe is a passionate entrepreneur and the driving force behind OPUVIA, a social innovation company focused on EdTech, career-professional development, and talent management.`,
        long_description: `As Founder & CEO, Mr. Atabe leads the company's mission to empower individuals and organizations through innovative educational solutions and fostering a skilled workforce. His dedication to social impact is evident in OPUVIA's work, which equips individuals with the tools and knowledge they need to thrive in the ever-evolving professional landscape`,
        position: "Founder & CEO"
    },
    {
        name: "Leslie E. NJUME",
        image_url: "/team/leslie_team.png",
        email: "leslie.njume@opuvia.org",
        short_description: "Leslie E. NJUME is a dedicated people-performance coach and mentor, inspiring and leading young professionals to prepare for and grab the best opportunities in the professional marketspace. ",
        long_description: `He is an operations and projects management expert, and a business development professional across industries. His interest in EduTech - Career and Professional development paved the way fine-tuning his role at Opuvia, where he serves as Vice President and Chief Operations Officer. 
        Primarily, Leslie supports Opuvia by leading the partnering with industry stakeholders and employers in the understanding and interpreting of industry skills needs, and there-on designing tailor-made industry-centred skills and career solutions to solve employability challenges - a consultant, Trainer and researcher par excellence - leveraging his over 15 years of industry leadership experience.
        Besides his role at Opuvia, Leslie is freelance Business development consultant; an industry consultant (Adjunct lecturer) for professional programmes in universities across the country, some which he helped design. 
        `,
        position: "Vice President and Chief Operations Officer"
    },
    {
        name: "Florence Ebob Ndum",
        image_url: "/team/florence_team.png",
        email: "ndum.f@opuvia.org",
        short_description: "Hey there, I'm Florence Ebob Ndum, and I help propel Opuvia forward in my capacity as Chief Technological Officer.",
        long_description: `I am experienced in IT Business Analysis, IT Project Management, and Agile Leadership. I'm dedicated to aligning our tech initiatives with our broader business goals. As the Agile Project Manager, I lead our Tech team through every delivery with a keen eye for detail and a knack for keeping things on track. With my expertise in Agile Requirements Analysis, I bridge the gap between ideas and reality, ensuring we deliver exactly what our Opuvia Business needs, when they need it.`,
        position: "Chief Technology Officer"
    },


    {
        name: "Nwendo Malaika Fehseh",
        image_url: "/team/malaika_team.png",
        email: "programmes@opuvia.org",
        short_description: "I am Nwendo Malaika Fehseh's Programme Officer for OPUVIA.",
        long_description: `I am passionate about seeing people, young people in particular become a better version of themselves, make the right career choices and make great exploits in their career choice, thus at OPUVIA on a regular day, I am into CAREER COUNSELLING, CORDINATING TRAININGS AND DEVELOPING NEW PROGRAMMES. These have been my activity for the past 8 years.
        As a social innovation, EduTech, professional career development and Talent management company, with MISSION to bridge the skill gap between job seekers and employer needs for the 21st century job market, Opuvia’s mission aligns perfectly with my Career focus and objectives. I make it an obligation to see into it that every client that subscribes to our services at OPUVIA goes back fulfilled and makes the most of their career and professional journeys. `,
        position: "Programme Officer"
    },
    {
        name: "Kuinang NZOUTCHOUM Christe",
        image_url: "/team/christe_team.png",
        email: "info@opuvia.org",
        short_description: `Kuinang NZOUTCHOUM Christe is currently serving as the Marketing Communications Officer at Opuvia Ltd. She holds a B.Sc. degree in Journalism and Mass Communication, and a Masters Degree in International Relations, both from the University of Buea.        `,
        long_description: `
        Miss Kuinang focuses on effectively promoting Opuvia's services and brand to the target audience. Additionally, as Marketing Communications Officer, she collaborates closely with other team members to gather insights, understand customer needs, and align marketing efforts with overall business objectives. 
        `,
        position: "Marketing Communications Officer"
    },
    {
        name: "Oben Desmond",
        image_url: "/team/desmond_team.png",
        email: "oben.d@opuvia.org",
        short_description: `Desmond is a Software Engineer, providing IT Services at Opuvia, specifically challenging the way users interact with and fulfill their needs of an IT-enhanced Career and professional development journey. 
        `,
        long_description: `Desmond developes the designs for OPUVIA products and also builds the user interfaces through which OPUVIA users interact with the system`,
        position: "Frontend Developer & UI/UX Designer"
    },

    // Add more team members as needed
];




export const partners = [

    {
        name: "Internation University of Applied Sciences",
        logo_url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2gSB_I2VfEz2yB90Ew7n4q2V4nK5EyY-PYwWBS0OObw&s",
        link: "https://iu.org"
    },
    {
        name: "Toast Master International",
        logo_url: "https://upload.wikimedia.org/wikipedia/en/0/05/Toastmasters_2011.png",
        link: "https://toasmasters.org"

    },
    {
        name: "Aeco Education",
        logo_url: "https://www.aecoedu.com/assets/logo.png",
        link: "https://aecoedu.com"
    },
    {
        name: "MHISS",
        logo_url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPWEnGVs--xoumsfXiMPGJG7nSTUYIJ6VsPuyl_aBrgw&s",
        link: "https://mhiss.study/about"
    },

    {
        name: "OSH Lab Consulting",
        logo_url: "/partners/osh-lab.png",
        link: "https://oshlabconsulting.org "
    },
    {
        name: "Lamp Light Centre (LLC NGO)",
        logo_url: "/partners/llc.png",
        link: "https://lamplightcentre.org"
    }


]
