import axios from "axios";
import { useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useCareerServices } from "../../hooks/useCareerServices";
import { IonSpinner } from "@ionic/react";
import ConfirmationModal from "../Layout/confirmationPop";

const CareerCouncilingApplicationForm = () => {
  const requiredAsterix = <span className="text-red-500">*</span>;


  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [message, setMessage] = useState("");
  const { createCareerCounselling, loading, error } = useCareerServices()

  const [viewModal, setViewModal] = useState(false);

  const [formSubmited, setFormSubmited] = useState(false)

  async function handleSubmitForm(e: any) {
    e.preventDefault();

    const form = {
      first_name: firstName,
      last_name: lastName,
      email,
      phonenumber,
      message,
    };

    try {
      const result = await createCareerCounselling(form)
      if (result) {
        setFormSubmited(true);
        setViewModal(true)

      }
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <div className="relative pt-10">
      {viewModal && (
        <ConfirmationModal
          popupTitle={"Application Recieved Successfully"}
          popupDescription={
            "Thank you for submiting the form. This is to acknowledge your application was submitted successfully"
          }
          popupActionButton={[
            <button
              className="bg-orange-500 text-white p-2 px-5 rounded-md w-full"
              onClick={() => setViewModal(false)}
            >
              Ok, Thanks
            </button>,
          ]}
          popupWidth={"400px"}
        />
      )}
      {!formSubmited && <>
        <h2 className="text-xl font-bold mt-4 mb-6">Apply Today</h2>
        <form
          onSubmit={handleSubmitForm}
          className="grid md:grid-cols-2 gap-5"
        >

          <>
            <div className="">
              <label>First Name {requiredAsterix}</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="e.g john"
                required
                className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
              />
            </div>

            <div className="">
              <label>Last Name {requiredAsterix}</label>
              <input
                type="text"
                value={lastName}
                required
                onChange={(e) => setLastName(e.target.value)}
                placeholder="e.g doe"
                className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
              />
            </div>

            <div className="">
              <label> Email {requiredAsterix}</label>
              <input
                type="text"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                placeholder="e.g johndoe@gmail.com"
                maxLength={255}
                className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
              />
            </div>
            <div className="">
              <label> Phone {requiredAsterix}</label>
              <input
                type="number"
                value={phonenumber}
                required
                onChange={(e) => setPhonenumber(e.target.value)}
                placeholder="e.g 23767100000"
                className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
              />
            </div>
          </>

          <div className="col-span-2">
            <label>Any Special Message</label>
            <textarea
              name=""
              id=""
              rows={4}
              value={message}
              required
              onChange={(e) => setMessage(e.target.value)}
              className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
            ></textarea>
          </div>

          <div>
            <button className="bg-orange-500 text-white p-2 px-5 rounded-md w-auto">
              {loading ? <IonSpinner /> : "Apply Now"}
            </button>
          </div>
        </form>
      </>}
    </div>
  );
};

export default CareerCouncilingApplicationForm;
