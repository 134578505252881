import React from 'react'
import { Link } from 'react-router-dom'

const HeroFair = () => {
    return (
        <div>
            <div className="relative">
                <div className='h-[80vh]  bg-cover ' style={{ backgroundImage: `url(https://images.pexels.com/photos/9432945/pexels-photo-9432945.jpeg?auto=compress&cs=tinysrgb&h=627&fit=crop&w=1200)` }}>
                    <div className='  bg-black h-full bg-opacity-60  left-0 p-6 px-10 2xl:px-60'>
                        <div className=" md:grid grid-cols-5 absolute top-1/2 -translate-y-1/2">
                            <div className='col-span-3'>
                                <h2 style={{ lineHeight: "50px" }} className="text-4xl lg:text-5xl font-medium text-white">
                                    OPUVIA Education and <div className='text-orange-500'>Career Fair  </div></h2>
                                <div className="mt-10">
                                    <div className='mb-3'>
                                        <Link to="https://app.opuvia.org/signup">
                                            <button className='bg-orange-500 text-white p-2 px-5 rounded-md w-auto'>
                                                Get Started for Free
                                            </button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to={"https://app.opuvia.org/signup"}>
                                            <button className='bg-white text-orange-500 p-2 px-5 rounded-md w-auto'>
                                                Partner with us
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HeroFair