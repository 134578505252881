import React from 'react'
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa'
import logo from "../../assets/images/white-logo.png"
import { IoMailOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { useContacts } from '../../hooks/useContacts'


const Footer = () => {
    const { footerLinks, footerHeading } = style
    const { contact } = useContacts()
    return (
        <section className='bg-opuviaBlue-500 text-white '>
            <div className="block sm:grid md:grid-cols-2 lg:grid-cols-5   p-20">
                <div className='mb-14'>
                    <img src={logo} className='h-10' alt="opuvia logo" />
                    Douala, Cameroon
                    {contact?.email && <div className='flex items-center gap-x-1'>
                        <IoMailOutline />  {contact?.email}
                    </div>}
                    <div className="mt-5">
                        <p className="font-bold mt-4 ">
                            Connect with us
                        </p>
                        {contact?.facebook && <a target="__blank" href={contact?.facebook}><FaFacebook className='h-6 w-6 inline-block ml-2' /></a>}
                        {contact?.facebook && <a target="__blank" href={contact?.instagram}><FaInstagram className='h-6 w-6 inline-block ml-2' /></a>}
                        {contact?.whatsapp_number && <a target="__blank" href={`https://api.whatsapp.com/send?phone=${"237683165278 "}`}> <FaWhatsapp className='h-6 w-6 inline-block ml-2' /></a>}
                    </div>
                    <div className="mt-2">
                        +237 683 165 278 <br />
                        +237 672 624 892
                    </div>
                </div>
                <div className='mb-14'>
                    <div className={footerHeading}>Company</div>
                    <div className=''>
                        <Link className={footerLinks} to="/about">About us</Link>  <br />
                        <Link className={footerLinks} to="/about">what we do</Link>  <br />
                        <Link className={footerLinks} to="/about/#team">Leadership/Team</Link>  <br />
                        <Link className={footerLinks} to="/career/career-counselling">Careers </Link>  <br />
                        <Link className={footerLinks} to="/home/#partners">  Partners </Link><br />
                        <Link className={footerLinks} to="mailto:info@opuvia.org">  Contact us</Link><br />
                    </div>

                </div>
                <div className='mb-14'>
                    <div className={footerHeading}>Programs</div>
                    <div className=''>
                        <Link className={footerLinks} to="/about/#scholar">Opuvia Scholar</Link> <br />
                        <Link className={footerLinks} to="/career/institute">Opuvia Career Institute</Link> <br />
                        <Link className={footerLinks} to="/opuvia-fair"> Opuvia Fair</Link>   <br />

                    </div>
                </div>
                {/* <div className='mb-14'>
                    <div className={footerHeading}>information For</div>
                    <div className=''>
                       Prospective students  <br />
                        Alumni<br />
                        Programmes <br /> 
                    </div>
                </div> */}
                <div className='mb-14'>
                    <div className={footerHeading}>Quick Links</div>
                    <div className=''>
                        {/* Trainings <br />
                        Coach with us<br />
                        Online Degrees
                        <br /> */}
                        <Link className={footerLinks} to="/partner-with-us"> Partner with us </Link><br />
                    </div>

                </div>
            </div>
        </section >
    )
}

export default Footer


const style = {
    footerLinks: 'hover:text-orange-500 hover:underline transition-all capitalize',
    footerHeading: "font-bold text-xl mb-2 capitalize"
}