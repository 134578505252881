import React, { useEffect, useState } from "react";
import { IoCloudUploadOutline, IoFileTrayOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header.component";
import CareerCouncilingApplicationForm from "../../components/Career Services/CareerCouncilingAppForm.screen";
import Title from "../../components/Header/Title.component";

const CareerCounceling = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <Title title='Career Counselling | OPUVIA' description='Career counselling is a process that helps individuals identify and explore career options, make informed decisions about their future, and develop strategies to achieve their goals. It can be helpful at any stage of life, but it is especially beneficial for young people and fresh graduates who are just starting out in their careers.' />
      <Header scrolled={scrolled} />
      <div className="relative">
        <section>
          <section className="relative">
            <div
              className="h-[60vh]  bg-cover "
              style={{
                backgroundImage: `url(https://www.counselling.org/images/default-source/aca-blog/counselling-corner/pathways.png?sfvrsn=19f92c2c_4)`,
              }}
            >
              <div className="  bg-black h-full bg-opacity-60  left-0 p-6 px-10 2xl:px-32">
                <div className=" md:grid grid-cols-5 absolute top-1/2 -translate-y-1/2 ">
                  <div className="col-span-3">
                    <h2
                      style={{ lineHeight: "50px" }}
                      className="text-4xl lg:text-5xl font-medium text-white"
                    >
                      Career Counselling
                    </h2>
                    <p className="text-white">
                      Increase your chances of getting your dream job
                    </p>
                    <div className="mt-10">
                      <div className="mb-3">
                        <a href="#counselling-form">
                          <button className="bg-orange-500 text-white p-2 px-5 rounded-md w-auto">
                            Apply Now
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="p-10 2xl:px-32">
            <div>
              <h2 className="text-xl font-bold mb-4">Introduction</h2>
              <p className="">
                Career counselling is a process that helps individuals identify and explore career options, make informed decisions about their future, and develop strategies to achieve their goals. It can be helpful at any stage of life, but it is especially beneficial for young people and fresh graduates who are just starting out in their careers.
                <p className="py-4">
                  To some, Career Counselling can help effectively sieve through the numerous options available to them, while to others, it comes as a revelation of options for their professional journey. Be as it may, after graduating from high schools or Universities, most graduates feel a sense of emptiness and are often unsure about where to start their job search, even questioning at some points, the value for the education they received.
                </p>
                Career counselling can be very useful for these graduates, as it can prepare them for immediate career clarity, satisfaction and a long, prosperous professional life. Career counselling helps individuals recognize the need for upskilling, Re-skilling, continuous learning and choice-making there-of.
              </p>
            </div>
            <div className="mt-10">
              <h2 className="text-xl font-bold mb-4">After our Career Counselling session, you will be able to better:</h2>
              <ol className="pl-10">
                <li>
                  - After our Career Counselling session, you will be able to better:
                </li>
                <li>
                  - understand yourself in a professional perspective

                </li>
                <li>
                  - explore your interests, values, and skills

                </li>
                <li>
                  - understand the relationship between your education and career goals

                </li>
                <li>
                  - make informed decisions about your future career choice and path.
                </li>
              </ol>

            </div>
            <div className="mt-10">
              <h2 className="text-xl font-bold mb-4">Why Choose Opuvia for your Career Counselling, guidance and Professional Development Journey:
              </h2>
              <ol className="pl-10">

                <li>-  We will give you real life-based plans</li>
                <li>- We provide you with personal support which is unique to your career needs and accompany you through your career and professional journey </li>
                <li>- We expose you to our employers’ and coaches’ network</li>
                <li>- We conduct real time research on areas needing development.</li>
                <li>- We are open to all age groups</li>
                <li>- We will help you see areas of your career needing re-skilling.</li>
                <li>- We are available physically and virtually </li>
                <li>- We are very affordable</li>
              </ol>

            </div>
            <p>
              <img src="/users/career-counselling.png" alt="career counselling pages" className="lg:w-1/2" />
            </p>
            <div className="mt-10">
              <h2 className="text-xl font-bold mt-4"> Virtual Counselling:
              </h2>
              <p>
                We have virtual slots available on a rolling basis. Contact our Front Desk on info@opuvia.org to book a virtual session.
              </p>
              <h2 className="text-xl font-bold mt-4">
                Pricing Per session:
              </h2>
              <p>
                At OPUVIA our price per session for career counselling stands at 5000FCFA for students, 10.000FCFA for Job seekers and 20.000FCFA for professionals.
              </p>
              <h2 className="text-xl font-bold mt-4">
                Payment modes:
              </h2>
              <p>
                Payments are done both through mobile money payments or cash payments at our office.
              </p>
              <h2 className="text-xl font-bold mt-4">
                Session booking:
              </h2>
              <p>
                you can book for a session both online (www.opuvia.org )  or in person at our office in Douala or Buea. For more information on the location of our offices in the above mentioned towns you can contact +237 683-165-278.
              </p>
              <h2 className="text-xl font-bold mt-4">
                Counselling day
              </h2>
              <p>
                Career Counselling sessions are set to hold on the last Friday of every month.
              </p>


            </div>
            <div className="mt-10">
              <h2 className="text-xl font-bold mb-4"> Available schedules open to public
              </h2>
              <p>
                We have 16 in-person slots available per open door day per month for bookings, this allows for flexibility and allows individuals to book slots convenient to their schedule of activity in a day. The slots are as follows:
              </p>
              <p>
                {/* <img src="/users/career-counselling" alt="career counselling pages" /> */}
              </p>
            </div>
            <div className="mt-10">
              <h2 className="text-xl font-bold mb-4">Bottomline Importance of Career Counselling:
              </h2>
              <ol className="pl-10">

                <li>- Career counsellors can help you identify these barriers, as well as ways to overcome them;</li>
                <li>- Students and professionals alike often fall into a vicious pattern of thinking that hinders their success. Career counselling helps eliminate this;</li>
                <li>- We conduct real time research on areas needing development.</li>
                <li>- Career counsellors can help you identify your hidden strengths and talents.</li>
              </ol>

            </div>
          </section>
        </section>
        <section className="p-10 2xl:px-32" id="counselling-form">
          <CareerCouncilingApplicationForm />
        </section>
      </div>
    </div>


  );
};

export default CareerCounceling;
