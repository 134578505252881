import React, { useState } from "react";
import { benefits, programs } from "../../data/programs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { UtilityService } from "../../api/utilities";

const ClientelleOfferSection = () => {
  const [selectedView, setSelectedView] = useState<
    "talents" | "educators" | "employers" | "coaches"
  >("talents");
  const active = `bg-orange-500 text-white rounded-full`;
  const [pos3, setPos3] = useState("top-[40px]  left-[600px] h-[250px]");
  const [pos2, setPos2] = useState("top-[20px]  left-[450px] h-[300px]");
  const [pos1, setPos1] = useState("top-1/2  left-[240px] h-[350px]");
  const [pos0, setPos0] = useState("top-0    left-[40px]  h-[400px]");
  const positions = [
    "top-0  left-[40px] h-[400px] z-40",
    "top-1/2  left-[240px] h-[350px] z-30",
    "top-[40px]  left-[450px] h-[300px] z-20",
    "top-1/2  left-[600px]  h-[250px] z-10",
  ];
  const [selectedItem, setSelectedItem] = useState(0);

  function selectTab(tab: "talents" | "educators" | "employers" | "coaches") {
    setSelectedView(tab);
    if (tab == "talents") {
      setPos0(positions[0]);
      setPos1(positions[1]);
      setPos2(positions[2]);
      setPos3(positions[3]);
      setSelectedItem(0);
    } else if (tab == "employers") {
      setPos0(positions[3]);
      setPos1(positions[0]);
      setPos2(positions[1]);
      setPos3(positions[2]);
      setSelectedItem(1);
    } else if (tab == "educators") {
      setPos0(positions[2]);
      setPos1(positions[3]);
      setPos2(positions[0]);
      setPos3(positions[1]);
      setSelectedItem(2);
    } else {
      setPos0(positions[1]);
      setPos1(positions[2]);
      setPos2(positions[3]);
      setPos3(positions[0]);
      setSelectedItem(3);
    }
  }

  return (
    <div className="py-10 pb-3 px-6 2xl:px-60  max-h">
      <div className="font-bold text-2xl uppercase">
        <h2>WHAT WE OFFER OUR CLIENTELE</h2>
        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
      </div>
      <div className="tabs hidden xl:block">
        <div className="w-[60vw] text-center bg-orange-100  m-auto rounded-full grid grid-cols-4">
          <div
            className={`transition-all duration-300 cursor-pointer p-3 ${selectedView == "talents" ? active : ""
              }`}
            onClick={() => selectTab("talents")}
          >
            Talents{" "}
          </div>
          <div
            className={`transition-all duration-300 cursor-pointer p-3 ${selectedView == "employers" ? active : ""
              }`}
            onClick={() => selectTab("employers")}
          >
            Employers
          </div>
          <div
            className={`transition-all duration-300 cursor-pointer p-3 ${selectedView == "educators" ? active : ""
              }`}
            onClick={() => selectTab("educators")}
          >
            Educators
          </div>
          <div
            className={`transition-all duration-300 cursor-pointer p-3 ${selectedView == "coaches" ? active : ""
              }`}
            onClick={() => selectTab("coaches")}
          >
            Coaches
          </div>
        </div>
        <div className="grid-cols-12 py-10 grid ">
          <div className="col-span-4   min-h-[400px]">
            <div className="relative  h-full ">
              {benefits.map((b, i) => {
                const isActive = b.type == selectedView;
                const result = UtilityService.ParseHeadings(b.description);
                return (
                  <div
                    key={i}
                    className={`${isActive
                      ? "visible top-1/4  opacity-100"
                      : "invisible top-1/3 opacity-0"
                      } delay-400  absolute duration-500 transition-all`}
                  >
                    <p>{result}</p>
                    {/* <button className="text-white bg-orange-500 rounded-md p-2 px-3 mt-5">
                      Learn More
                    </button> */}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-span-6 relative ">
            <div className="relative ">
              <img
                src={benefits[3].image}
                className={` m-8 transition-all duration-500 object-cover shadow-sm absolute w-[240px]  rounded-lg ${pos3}  `}
                alt={benefits[3].description}
              />
              <img
                src={benefits[2].image}
                className={` m-8 transition-all duration-500 object-cover shadow-sm absolute w-[240px]  rounded-lg ${pos2}  `}
                alt={benefits[2].description}

              />
              <img
                src={benefits[1].image}
                className={` m-8 transition-all duration-500 object-cover shadow-sm absolute w-[240px]  rounded-lg ${pos1}  `}
                alt={benefits[1].description}
              />
              <img
                src={benefits[0].image}
                className={` m-0 transition-all duration-500 object-cover shadow-sm absolute w-[240px]  rounded-lg  ${pos0} `}
                alt={benefits[0].description}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-view block xl:hidden">
        <div className="w-[90vw] mb-10 text-center bg-orange-100  m-auto rounded-full grid grid-cols-4">
          <div
            className={`transition-all duration-300 cursor-pointer p-3 ${selectedView == "talents" ? active : ""
              }`}
            onClick={() => selectTab("talents")}
          >
            Talents{" "}
          </div>
          <div
            className={`transition-all duration-300 cursor-pointer p-3 ${selectedView == "employers" ? active : ""
              }`}
            onClick={() => selectTab("employers")}
          >
            Employers
          </div>
          <div
            className={`transition-all duration-300 cursor-pointer p-3 ${selectedView == "educators" ? active : ""
              }`}
            onClick={() => selectTab("educators")}
          >
            Educators
          </div>
          <div
            className={`transition-all duration-300 cursor-pointer p-3 ${selectedView == "coaches" ? active : ""
              }`}
            onClick={() => selectTab("coaches")}
          >
            Coaches
          </div>
        </div>
        <Carousel
          centerMode
          showIndicators
          selectedItem={selectedItem}
          className="w-full  "
          showThumbs={false}
          showArrows
        >
          {benefits.map((benefit, index) => {
            return (
              <div key={index} className="p-2">
                <div
                  key={index}
                  className="mb-0 w-full h-full bg-white rounded-md border-gray-200  border"
                >
                  <img
                    className="h-[350px] rounded-t-md w-full object-cover"
                    src={benefit.image}
                    alt=""
                  />
                  <div className="p-3 pb-4">
                    <h2 className="text-2xl font-bold my-2 capitalize">
                      {benefit.type}
                    </h2>
                    <p className="text-gray-600 text-sm">
                      {benefit.description}
                    </p>
                    {/* <button className="w-[100px] p-1 rounded-md mt-3 text-white bg-opuviaBlue-500">
                      Read More
                    </button> */}
                  </div>
                </div>
                <div className="h-10"></div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default ClientelleOfferSection;
