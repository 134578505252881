// src/components/NotFound.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../components/Header/Header.component';

const NotFound: React.FC = () => {
    return (
        <div>
            <Header scrolled={true} />
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
                <h1 className="text-8xl font-bold text-orange-600">404</h1>
                <p className="text-xl mt-4 text-gray-700">Page Not Found</p>
                <NavLink to="/" className="mt-6 text-orange-500 hover:underline">
                    Go Back to Home
                </NavLink>
            </div>
        </div>
    );
};

export default NotFound;
