// src/hooks/useEvents.ts
import { useState, useEffect } from 'react';
import axiosInstance from '../api/axios';

export interface Event {
  id?: number;
  event_name: string;
  start_date: string;
  event_link: string;
  event_type: string;
  fee: string;
  photo?: string;
  delivery_mode: string;
  short_description: string;
  full_description: string;
}

interface CreateEventData {
  event_name: string;
  start_date: string;
  event_link: string;
  event_type: string;
  fee: string;
  photo?: string;
  delivery_mode: string;
  short_description: string;
  full_description: string;
}

interface UpdateEventData {
  event_name?: string;
  start_date?: string;
  event_link?: string;
  event_type?: string;
  fee?: string;
  photo?: string;
  delivery_mode?: string;
  short_description?: string;
  full_description?: string;
}

export const useEvents = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchEvents = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('/events');
      setEvents(response.data.data);
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to fetch events");
    } finally {
      setLoading(false);
    }
  };

  const createEvent = async (data: CreateEventData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post('/events', data);
      setEvents((prevEvents) => [...prevEvents, response.data.data]);
      return response.data.data;
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to create event");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateEvent = async (id: number, data: UpdateEventData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.put(`/events/${id}`, data);
      setEvents((prevEvents) =>
        prevEvents.map((event) => (event.id === id ? response.data.data : event))
      );
      return response.data.data;
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to update event");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deleteEvent = async (id: number) => {
    setLoading(true);
    setError(null);
    try {
      await axiosInstance.delete(`/events/${id}`);
      setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to delete event");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return {
    events,
    loading,
    error,
    fetchEvents,
    createEvent,
    updateEvent,
    deleteEvent,
  };
};
