import React, { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle';
import { EventsContext, } from '../../pages/home/Home.screen';
import { IoChevronBack, IoChevronForward, } from 'react-icons/io5';

const Hero = () => {
    const upcomingEvents = useContext(EventsContext);
    const swiperRef = useRef(null); // Use ref to get Swiper instance

    const images = [
        'https://images.pexels.com/photos/3762362/pexels-photo-3762362.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        'https://images.pexels.com/photos/2381069/pexels-photo-2381069.jpeg?auto=compress&cs=tinysrgb&w=800',
        'https://images.pexels.com/photos/4560079/pexels-photo-4560079.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        'https://images.pexels.com/photos/5685773/pexels-photo-5685773.jpeg?auto=compress&cs=tinysrgb&w=800',
        'https://images.pexels.com/photos/3869651/pexels-photo-3869651.jpeg?auto=compress&cs=tinysrgb&w=800',
        'https://images.pexels.com/photos/4560170/pexels-photo-4560170.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    ];

    const data = [
        {
            url: 'https://images.pexels.com/photos/7683743/pexels-photo-7683743.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
            title: "We Power Human Capital | Globally",
            button1: {
                url: "https://app.opuvia.org/signup",
                text: "Get Started for Free"
            },
            button2: {
                url: "https://app.opuvia.org/signup",
                text: "Partner with us"
            },
            description: ''
        },
        ...upcomingEvents.map((event, index) => ({
            url: images[index % images.length],
            title: event.event_name,
            description: event.full_description,
            button1: {
                url: event.event_link,
                text: "Join Event"
            },
            button2: null
        }))
    ];

    return (
        <div className="relative h-[90vh]">
            <Swiper
                ref={swiperRef} // Attach ref here to access the Swiper instance
                slidesPerView={1}
                autoplay={{
                    delay: 8000,
                    disableOnInteraction: false,
                }}
                style={{
                }}
                effect={'flip'}
                loop={false}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    enabled: true,

                }}
                onSlideChange={() => console.log('slide change')}
                modules={[Autoplay, Navigation, A11y]}
            >
                {data.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className='h-[90vh] bg-cover' style={{ backgroundImage: `url(${item.url})`, backgroundPosition: "center" }}>
                            <div className='bg-black h-full bg-opacity-60 left-0 p-6 px-10 2xl:px-60 relative'>

                                <div className="md:grid grid-cols-5 absolute top-1/2 -translate-y-1/2">
                                    <div className='col-span-3'>
                                        <h2 style={{ lineHeight: "50px" }} className="text-4xl lg:text-5xl font-medium text-white">
                                            {item.title.split("|")[0]} <div className='text-orange-500'>{item.title.split("|")[1]}</div>
                                        </h2>
                                        <p className='text-gray-200 text-lg'>
                                            {item.description.substring(0, 150)}
                                        </p>
                                        <div className="mt-10">
                                            <div className='mb-3'>
                                                {item.button1 && <a href={item.button1.url}>
                                                    <button className='bg-orange-500 text-white p-2 px-5 rounded-md w-auto'>
                                                        {item.button1.text}
                                                    </button>
                                                </a>}
                                            </div>
                                            <div>
                                                {item.button2 && <a href={item.button2.url}>
                                                    <button className='bg-white text-orange-500 p-2 px-5 rounded-md w-auto'>
                                                        {item.button2.text}
                                                    </button>
                                                </a>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
                <div className='absolute z-1000 top-1/2 left-0 w-full flex items-center justify-between px-10 text-white'>
                    {/* Use swiperRef.current to navigate */}
                    <button className='swiper-button-prev '>
                    </button>
                    <button className='swiper-button-next'>
                    </button>
                </div>
            </Swiper>

        </div>
    );
}

export default Hero;
