import { Helmet } from 'react-helmet';

export default function Title({ title, description }: { title: string, description: string }) {
    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name={description} content="My page description" />
            </Helmet>
        </div>
    );
}
