import { useContext, useState, useEffect, useRef } from "react";
import { BiMenu, BiSearch, BiBell } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/Header/Header.component";

const UniPlacement: React.FC = () => {

    const scriptRef = useRef<HTMLDivElement>(null)

    useEffect(() => {

        async function addForm() {
            const script = document.createElement("script");

            script.src = "https://opuvia.ams4you.net/webinqcode-14241-1179.html";
            script.async = true;

            scriptRef.current?.appendChild(script)

        }
        addForm()
    }, []);




    return (
        <>
            <div>
                <Header scrolled />
                <div className="h-24"></div>
                <div className="content p-5 text-center">
                    <h3 className="mb-4">Please fill the enquiry form below and we will get back to you</h3>
                    <iframe style={{ "border": "0px", "maxWidth": "100%", "minHeight": "90vh", "width": "80vw" }} src="https://ams4you.net/panel/webinq.php?ag=14241&wfid=1179&url='+location.href.replace('&', '[P]')+'&ptit='+document.title+'" height="700" width="770" scrolling="auto"></iframe>
                </div>
            </div>
        </>
    );
};

export default UniPlacement;
