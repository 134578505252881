import React, { useEffect, useState } from "react";
import { IoCloudUploadOutline, IoFileTrayOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header.component";
import InterviewPrepApplicationForm from "../../components/Career Services/InterviewPrepApplicationForm.screen";
import Title from "../../components/Header/Title.component";

const InterviewPrep = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Title title='Interview Preparations | OPUVIA' description='You might already know that an updated resume and a polished cover letter will help you stand out in a crowd of candidates. But do you know how to make yourself shine in your next job interview?' />
      <Header scrolled={scrolled} />

      <div className="relative">
        <div
          className="h-[50vh]  bg-cover "
          style={{
            backgroundImage: `url(https://www.ftdacademy.com/content/images/2021/05/Interviewing.png)`,
          }}
        >
          <div className="  bg-black h-full bg-opacity-60  left-0 p-6 px-10 2xl:px-32">
            <div className=" md:grid grid-cols-5 absolute top-1/2 -translate-y-1/2 ">
              <div className="col-span-3">
                <h2
                  style={{ lineHeight: "50px" }}
                  className="text-4xl lg:text-5xl font-medium text-white"
                >
                  Interview Preparation
                </h2>
                <p className="text-white">
                  Increase your chances of getting your dream job
                </p>
                <div className="mt-10">
                  <div className="mb-3">
                    <a href="#prep-form">
                      <button className="bg-orange-500 text-white p-2 px-5 rounded-md w-auto">
                        Apply Now
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="p-10 2xl:px-32 ">
        <div className="space-y-8">
          <div>
            <div className="space-y-2">
              <h2 className='text-xl font-bold '>Interview Preperations</h2>
              <p>
                You might already know that an updated resume and a polished cover letter will help you stand out in a crowd of candidates. But do you know how to make yourself shine in your next job interview?
              </p>
              <p>
                Understanding the kinds of questions, you’ll likely be asked during a job interview can give you the opportunity to compose answers that best highlight your qualifications and why you’re the right candidate for the job.
              </p>
              <p>
                During this session, we’ll discuss all the top interview questions to expect during your next job interview, plus we’ll arm you with tips to prepare answers that will impress any interviewer.
              </p>
              <p>
                Additionally, we will simulate industry best practices for the role you are aspiring for, and we will design scenarios to allow for preparedness towards competency-based interviews.
              </p>
              <p>
                Finally, we will conduct a gap analysis of your current competence level versus required level for the job and we will determine what measures must eb taken to make you outstanding during the interview and emerge best candidate.
              </p>
            </div>
          </div>
          <div>
            <div className="space-y-2">
              <h2 className='text-xl font-bold '>Other services we provide besides the interview prep:</h2>
              <ul>
                <li>- Designing and presenting a top-tier CV</li>
                <li>
                  - Writing the best cover letter for yourself
                </li>
              </ul>
            </div>
          </div>
          <div>

            <div className="space-y-2">
              <h2 className='text-xl font-bold '>Pricing for services
              </h2>
              <ul>
                <li>- Interview Prep 10,000FCFA
                </li>
                <li>
                  - CV Design 10,000FCFA
                </li>
                <li>
                  - Cover Letter Writing 5,000FCFA.
                </li>
              </ul>
            </div>

          </div>
          <p className="mt-2">
            Take action now and grab your job at the next interview. 	Email us at {" "}
            <a className="link" href="mailto:info@opuvia.org">info@opuvia.org</a>
          </p>
        </div>

        <section id="prep-form">
          <InterviewPrepApplicationForm />
        </section>
      </section>
    </div>
  );
};

export default InterviewPrep;
