import React from "react";
import widgetImage from "../../assets/images/widget-image.png";

export default function StudyAtDreamUniversityWidget({ url }: { url: string }) {
  return (
    <div className="w-full md:h-[310px] rounded-2xl flex flex-wrap md:flex-nowrap gap-x-[40px] gap-y-[10px] bg-white shadow-[0px_4px_20px_0px_rgba(90,89,89,0.25)]">
      <div className="w-[450px] h-full overflow-hidden">
        <img
          src={widgetImage}
          alt="Widget Image"
          className="object-cover aspect-video w-full h-full rounded-t-2xl md:rounded-tr-none md:rounded-l-2xl"
        />
      </div>

      <div className="flex flex-col justify-center p-8">
        <h2 className="text-[25px] md:text-[30px] font-bold leading-normal font-poppins mb-[11px]">
          Study at your dream university
        </h2>
        <p className="text-sm md:text-lg font-normal leading-normal mb-4">
          Are you looking to study abroad, within your country or online. Visit
          our placement Portal{" "}
        </p>
        <button className="px-4 py-2 rounded-lg text-white bg-orange-500 w-fit">
          <a href={url} target="_blank" rel="noopener noreferrer">
            Get Placed!
          </a>
        </button>
      </div>
    </div>
  );
}
