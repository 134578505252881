import React, { useEffect, useState } from "react";
import { BiMenu } from "react-icons/bi";
import whiteLogo from "../../assets/images/white-logo.png";
import orangeLogo from "../../assets/images/ornage-green.png";
import logo from "../../assets/images/ornage-green.png";
import NavigationLinks from "./NavigationLinks";
import { NavLink, useLocation } from "react-router-dom";

const navLinks = [
  { to: "/home", label: "Home" },
  { to: "/about", label: "About" },
  { to: "/opuvia-fair", label: "Opuvia Fair" },
  {
    to: "#",
    label: "Career Services",
    subLinks: [
      { to: "/career/career-counselling", label: "Career Counselling" },
      { to: "/career/interview-preparation", label: "Interview Prep" },
    ],
  },
  { to: "https://app.opuvia.org/login", label: "Login" },
  { to: "https://app.opuvia.org/signup", label: "Get Started for Free" },
];

const Header: React.FC<{ scrolled: boolean }> = ({ scrolled }) => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation()



  return (
    <>
      {!showMenu ? (
        <nav
          className={`fixed top-0 left-0 w-full z-50 p-4 md:px-32 flex items-center justify-between transition-all duration-300 ${true ? "text-white bg-opuviaBlue-500 bg-opacity-90" : "bg-transparent text-white"
            }`}
        >
          <img
            src={scrolled ? orangeLogo : whiteLogo}
            className="md:h-14 h-8 w-auto object-contain"
            alt="Opuvia Logo"
            id=""
          />
          <div className="hidden lg:flex items-center space-x-6">
            <NavigationLinks links={navLinks.slice(0, -1)} />
            <NavLink to={navLinks[5].to} className="ml-4">
              <button className="bg-orange-500 p-2 px-5 rounded-md">Get Started for Free</button>
            </NavLink>
          </div>
          <BiMenu
            onClick={() => setShowMenu(true)}
            className="text-3xl text-white cursor-pointer lg:hidden"
          />
        </nav>
      ) : (
        <div className="fixed top-0 right-0 w-full min-h-screen   z-50 flex ">
          <div className="w-[7%] sm:w-1/3 bg-opacity-70 bg-opuviaBlue-500" onClick={() => setShowMenu(false)}></div>
          <div className="w-[93%] sm:w-2/3 bg-opuviaBlue-500 p-6 ">
            <img src={logo} className="h-14 mx-auto mb-6" alt="Opuvia Logo" />
            <NavigationLinks links={navLinks.slice(0, -1)} isMobile toggleMenu={() => setShowMenu(false)} />
            <NavLink to={navLinks[5].to} className="block mt-6">
              <button className="bg-orange-500 p-2 px-5 rounded-md w-full">Get Started for Free</button>
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
