import { useIonToast } from "@ionic/react";
import { ICareerCounselling, IInterviewPrep } from "../interfaces/careerService.interface";
import { useState } from "react";
import axiosInstance from "../api/axios";

export const useCareerServices = () => {
  const [careerCounselling, setCareerCounselling] = useState<ICareerCounselling>();
  const [interviewPrep, setInterviewPrep] = useState<IInterviewPrep>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [toast] = useIonToast()

  const fetchCareerCounselling = async () => {
    // setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('career-counselling/');
      setCareerCounselling(response.data.data);
      // dispatch(setEducatorsContact(response.data.data))

    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to fetch career service  info");
    } finally {
      setLoading(false);
    }
  };

  const fetchInterviewPrep = async () => {
    // setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('interview-prep/');
      setInterviewPrep(response.data.data);
      // dispatch(setEducatorsContact(response.data.data))

    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to fetch career service  info");
    } finally {
      setLoading(false);
    }
  };

  const createCareerCounselling = async (data: ICareerCounselling) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post('career-counselling/', data);
      setCareerCounselling((prevInfo) => ({ ...prevInfo, ...response.data.data }));
      toast({ message: "Successfully added career service  info", duration: 3000, color: "success" })
      return response.data.data;
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to create career service Info");
      toast({ message: err.response?.data?.message || "Failed to create career service  info", duration: 4000, color: "danger" })
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const createInterviewPrep = async (data: IInterviewPrep) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post('interview-prep/', data);
      setInterviewPrep((prevInfo) => ({ ...prevInfo, ...response.data.data }));
      toast({ message: "Successfully added career service  info", duration: 3000, color: "success" })
      return response.data.data;
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to create career service Info");
      toast({ message: err.response?.data?.message || "Failed to create career service  info", duration: 4000, color: "danger" })
      throw err;
    } finally {
      setLoading(false);
    }
  };




  return {
    createInterviewPrep,
    fetchCareerCounselling,
    createCareerCounselling,
    loading,
    error
  };
};
