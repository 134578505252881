import React from 'react'
import { Link } from 'react-router-dom'

const HeroCareerInstitute = () => {
    return (
        <div>
            <div className="relative">
                <div className='h-[80vh]  bg-cover ' style={{ backgroundImage: `url(https://images.pexels.com/photos/2422287/pexels-photo-2422287.jpeg?cs=srgb&dl=pexels-jopwell-2422287.jpg&fm=jpg)` }}>
                    <div className='  bg-black h-full bg-opacity-60  left-0 p-6 px-10 2xl:px-60'>
                        <div className=" md:grid grid-cols-5 absolute top-1/2 -translate-y-1/2">
                            <div className='col-span-3'>
                                <h2 style={{ lineHeight: "50px" }} className="text-4xl lg:text-5xl font-medium text-white">
                                    OPUVIA <div className='text-orange-500'>Career Institute  </div></h2>
                                <div className="mt-10">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HeroCareerInstitute