import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer.component';
import Header from '../../components/Header/Header.component';
import Hero from '../../components/Home/Hero.component';
import ClientelleOfferSection from '../../components/Layout/ClientelleOfferSection.component';
import StudyAtDreamUniversityWidget from '../../components/StudyAtDreamUniveristyWidget/StudyAtDreamUniversityWidget';
import { programs, popular, testimonials, gettingStarted } from '../../data/programs';
import { aboutUsCareerIns } from '../../data/aboutus-content';
import HeroAbout from '../../components/Home/HeroAbout.component';
import { IoMail, IoMailOpen, IoMailOpenOutline, IoMailOutline } from 'react-icons/io5';
import { teamMembers } from '../../data/teams-partners';
import TeamMemberDetail from './TeamMemberDetail';
import { IonButton } from '@ionic/react';
import OpuviaTeamCard from '../../components/Home/OpuviaTeamCard.component';
import Title from '../../components/Header/Title.component';

const About = () => {
    const [scrolled, setScrolled] = useState(false);
    const [showMemberDetail, setShowMemberDetail] = useState(false);

    const aboutRef = useRef<HTMLDivElement>(null)




    return (

        <div className="relative" ref={aboutRef}>
            <Title description=' Opuvia is a social innovation, EdTech, Career-Professional Development
                                and Talent Management Company, focusing on the achievement of an Africa
                                and world where talents and skills are sharpened, in a manner that
                                exactly meets the needs of the continuously changing and challenging job
                                market.&nbsp;' title='About OPUVIA' />
            <Header scrolled={scrolled} />
            <HeroAbout />

            <div className="text-black bg-blue relative">
                <section className="py-10 px-6 2xl:px-60">
                    <div className="font-bold text-2xl">
                        <h2>ABOUT OPUVIA</h2>
                        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                    </div>
                    <p className="mb-2 md:flex" >
                        <div className='space-y-4 '>
                            <div>
                                Opuvia is a social innovation, EdTech, Career-Professional Development
                                and Talent Management Company, focusing on the achievement of an Africa
                                and world where talents and skills are sharpened, in a manner that
                                exactly meets the needs of the continuously changing and challenging job
                                market.&nbsp;
                            </div>
                            <p>
                                Opuvia has as mission to close the conventional gap between education and employment and curb the global problem of graduate under and unemployment. We believe that every person deserves an opportunity to succeed and thrive in their chosen career, and we are committed to providing the tools and resources necessary to make that a reality.
                            </p>
                            <p>
                                In a bid to ensure the achievement of this mission, Opuvia has divided her activities into 3 key units:
                            </p>
                        </div>
                    </p>

                </section>
                <section id="training" className=" bg-gray-50 py-10 px-6 2xl:px-60 space-y-4">
                    <div className="font-bold text-2xl uppercase">
                        <h2>Opuvia Career Institute</h2>
                        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                    </div>
                    <div className="">
                        <p>
                            Opuvia Career Institute is a prestigious educational institution dedicated to providing specialized training and empowering individuals to excel in their chosen careers with key services, programs, and advantages offered being:
                        </p>
                        <div className=" block   md:grid md:grid-cols-2 lg:grid-cols-3 gap-3 mt-5">
                            {aboutUsCareerIns.map((program, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="mb-10 bg-white rounded-md border-gray-200 border"
                                    >
                                        <img
                                            className="h-[300px] rounded-t-md w-full object-cover"
                                            src={program.image}
                                            alt={program.title}
                                        />
                                        <div className="p-3 pb-4 ">
                                            <h2 className="text-2xl font-bold my-2">{program.title}</h2>
                                            <div className="md: justify-between  md:flex flex-col">
                                                <p className="text-gray-600 text-sm">
                                                    {
                                                        program.points.map((point, index) => {
                                                            return (
                                                                <div key={index} className="flex gap-2">
                                                                    <div className="w-4 h-4 bg-orange-500"></div>
                                                                    <p>{point}</p>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>

                <section className="py-10 px-6 2xl:px-60">
                    <StudyAtDreamUniversityWidget
                        url={"/uni-placement"}
                    />
                </section>
                <section className="py-10 px-6 2xl:px-60" id='scholar'>
                    <div className="font-bold text-2xl uppercase">
                        <h2>Opuivia Scholar</h2>
                        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                    </div>

                    <p>
                        Opuvia Scholar brings you to a place to benefit from assistance from our platform for study admissions, as well as scholarship applications into universities, both national and international.
                    </p>
                </section>

                <section className="py-10 px-6 2xl:px-60">
                    <div className="font-bold text-2xl uppercase">
                        <h2>Opuvia Fair</h2>
                        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                    </div>

                    <section className="py-3 space-y-3">
                        <p>
                            OPUVIA FAIR is an international educational and career event, that brings a unique
                            opportunity for leading education and career service providers, top employers and recruiters to showcase their educational and employment opportunities to students and job seekers. The
                            event brings together all the stakeholders of the education and career industry for them to
                            showcase, network and create meaningful partnerships. </p>
                        <p>

                            At Opuvia, we are committed to nurturing talent, empowering individuals, and shaping the workforce of the future. We understand that the problem of graduate under and unemployment is complex and multifaceted, with many underlying factors contributing to the issue. That's why we take a holistic approach to our work, addressing not just the immediate needs of job seekers and employers, but also the systemic barriers that prevent individuals from accessing the right opportunities.
                            To achieve this, we bank on key values such as integrity (in our relationships with partners and clients), rationality (in our arguments and decision making) as well as collegiality in our work as professionals.
                        </p>
                    </section>
                </section>

                <section className="py-10 px-6 2xl:px-60 bg-gray-50">
                    <div className="font-bold text-2xl uppercase">
                        <h2>The Team</h2>
                        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                    </div>

                    <section className="py-3  grid md:grid-cols-3  gap-5">
                        {
                            teamMembers.map((team, index) => {
                                return (
                                    <OpuviaTeamCard team={team} key={index} />
                                )
                            })
                        }
                    </section>
                </section >

            </div>
            <Footer />
        </div>
    )
}

export default About