

export const aboutUsCareerIns = [
    {
        title: "Professional development trainings",
        image: "https://ce.ccsu.edu/wp-content/uploads/2018/09/professional-development-3.jpg",
        points: [
            "Guided Projects",
            "Masterclasses",
            "Opuvia Pathway Program",
            "Apprenticeships – Junior Professionals",
            "Corporate Trainings"
        ]
    },
    {
        title: "Employability skills development center",
        image: "https://media.istockphoto.com/id/1355030282/photo/shot-of-a-mature-businessman-using-a-laptop-in-a-modern-office.jpg?s=612x612&w=0&k=20&c=OItGhzI7XeKceicohbtU0q2ss0aFoMhF3kZfTYPXFLE=",
        points: [
            "Digital Career Services Center",
            "Job Search Tools",
            "Resume and Cover Letter Assistance",
            "Career Exploration and Assessment",
            "Interview Preparation",
            "Career Coaching and Guidance",
            "Skill Development and Training"
        ]
    },
    {
        title: "Manpower services",
        image: "https://images.pexels.com/photos/2422278/pexels-photo-2422278.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        points: [
            "Talent Search Solution",
            "Manpower Placement",
            "Showcase Employment Opportunities",
            "Manage Recruitments",
            "Showcase Gigs and Manage Servicing"
        ]
    }
]