// src/api/axiosInstance.ts
import axios from 'axios';


const { host } = window.location
const hostType: "localhost" | "live" | "test" = host.match("localhost") ? "localhost" : host.match("opuvia.org") ? "live" : "test";

const axiosInstance = axios.create({
    baseURL: hostType == "localhost" ? "http://localhost:5005/api" : hostType == "live" ? "https://backend.opuvia.org/api/" : "https://opuvia-backend.onrender.com/api/", // Set this environment variable to your API base URL
});


// Add a request interceptor to include the token in headers if available
axiosInstance.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default axiosInstance;
