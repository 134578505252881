import { IonCard, IonBadge, IonChip, IonButton } from '@ionic/react'
import React from 'react'
import { IEventResponse } from '../../interfaces/events.interface'
import { Link } from 'react-router-dom'
import { parseDate } from '../../api/utilities'

const EventsCard = ({ event }: { event: IEventResponse }) => {
    const date = parseDate(event.start_date);

    return (
        <IonCard button className="relative md:h-[270px]">
            <div className="p-3 md:h-[270px] ">
                <div className="flex gap-x-4 h-full ">
                    <div className='w-[100px] text-xl font-bold text-gray-700 flex flex-col items-center justify-center'>
                        <h2>{date?.day}</h2>
                        <h2  >{date?.month.shortName}</h2>
                        <h2>{date?.year}</h2>
                    </div>
                    <div className='h-full flex flex-col justify-between py-4'>
                        <h2 className="text-xl capitalize font-semibold text-black">{event.event_name}</h2>
                        <div>
                            <p className="text-sm font-normal">{event.short_description}</p>
                            <p className="text-sm font-normal">{event.full_description.substring(0, 100)}...</p>
                        </div>
                        <div className="flex-wrap  gap-x-1">
                            <IonChip color='dark' className="text-sm font-normal border" outline>{date?.timeago} {date?.type != "past" ? "" : "ago"}</IonChip>
                            <IonChip color='tertiary' className="text-sm font-normal border capitalize" outline>{date?.type}</IonChip>
                            <IonChip color='dark' className="text-sm font-normal border capitalize" outline>{event.fee.substring(0, 15)} {event.fee.split(" ")[1] ? "" : "CFA"}</IonChip>
                            <IonChip color='tertiary' className="text-sm font-normal border capitalize" outline>{event.event_type?.split("_").join(" ")}</IonChip>
                        </div>
                        {date?.type != "past" && <div className="mt-1">
                            <a href={event.event_link} target='__blank'>
                                <IonButton color={'warning'}>Join</IonButton>
                            </a>
                        </div>}
                    </div>
                </div>
            </div>
        </IonCard>
    )
}

export default EventsCard