import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonToolbar } from '@ionic/react'
import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps'
import React from 'react'
import { teamMembers } from '../../data/teams-partners'
import { IoClose, IoMailOutline } from 'react-icons/io5'

export interface TeamMemberDetailProps {
    isOpen: boolean
    onClose: () => void,
    member: typeof teamMembers[0]
}

const TeamMemberDetail: React.FC<TeamMemberDetailProps> = ({ isOpen, onClose, member }) => {
    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot='end'>
                        <IonButton color='danger' onClick={onClose}>
                            <div slot='start'><IoClose className='text-xl' /></div>
                            Close
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className='pt-4'>
                    <img src={member.image_url} className='h-[20rem] w-[20rem] object-cover rounded-full m-auto' alt={member.name + ' ' + member.position} />
                </div>
                <div className='p-5'>
                    <h1 className='font-bold text-lg text-center'>{member.name + " " + member.position}</h1>
                    <div className="space-y-3 mt-4">
                        <p>{member.short_description}</p>
                        <div className='space-y-2'>{member.long_description.split('\n').map((line, index) => <p key={index}>{line}</p>)}</div>
                        <div>
                            <h2 className='font-bold text-lg'>Contact</h2>
                            <a href={'mailto:' + member.email} target='_blank' rel='noreferrer' className='flex gap-x-2 items-center link'><IoMailOutline />{member.email}</a>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default TeamMemberDetail