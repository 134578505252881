import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer.component';
import Header from '../../components/Header/Header.component';
import Title from '../../components/Header/Title.component';
import HeroAbout from '../../components/Home/HeroAbout.component';
import HeroCareerInstitute from '../../components/Home/HeroCareerInstitute';

const CareerInstitute = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 200) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="relative">
            <Header scrolled={scrolled} />
            <Title description="Opuvia Career Institute is a prestigious educational institution dedicated to providing specialized training and empowering individuals to excel in their chosen careers." title="Opuvia Career Institute" />
            <HeroCareerInstitute />

            <div className="text-black bg-blue relative">
                <section className="py-10 px-6 2xl:px-60">
                    <div className="font-bold text-2xl">
                        <h2>Empowering Futures through Specialized Education</h2>
                        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                    </div>
                    <p>
                        Opuvia Career Institute is a prestigious educational institution dedicated to providing specialized training and empowering individuals to excel in their chosen careers. With a strong commitment to academic excellence, industry relevance, and holistic development, Opuvia Career Institute has emerged as a leading institution for career-focused education.
                    </p>
                </section>

                <section className="py-10 px-6 2xl:px-64 space-y-4">
                    <div className="md:flex h-full gap-x-8">
                        <img className="md:w-1/2 rounded-md" src="https://images.pexels.com/photos/6578395/pexels-photo-6578395.jpeg" alt="students in a classroom" />
                        <div className="flex flex-col items-center justify-center gap-y-10 h-100">
                            <div>
                                <div className="font-bold text-2xl">
                                    <h2>Professional Development Trainings</h2>
                                    <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                                </div>
                                <ul>
                                    <li><b>Guided Projects:</b> Step-by-step instructions to help you work through a project from start to finish, providing hands-on experience.</li>
                                    <li><b>Masterclasses:</b> Immersive learning experiences with demonstrations, case studies, and practical exercises.</li>
                                    <li><b>Opuvia Pathway Program:</b> Offers specialized coursework, internships, and mentorship opportunities for a clear academic and career pathway.</li>
                                    <li><b>Apprenticeships – Junior Professionals:</b> Hands-on experience alongside experienced professionals in a real work environment.</li>
                                    <li><b>Corporate Trainings:</b> Designed to enhance employees' skills, knowledge, and competencies within a company.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-10 px-6 2xl:px-64 space-y-4">
                    <div className="md:flex h-full gap-x-8">
                        <div className="flex flex-col justify-center gap-y-10 h-100 w-full">
                            <div>
                                <div className="font-bold text-2xl">
                                    <h2>Employability Skills Development Centre</h2>
                                    <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                                </div>
                                <ul>
                                    <li><b>Digital Career Services Center:</b> Provides job search tools, resume and cover letter assistance, career exploration and assessment, interview preparation, career coaching and guidance, and skill development and training.</li>
                                </ul>
                            </div>
                        </div>
                        <img className="md:w-1/2 rounded-md" src="https://images.pexels.com/photos/5648430/pexels-photo-5648430.jpeg" alt="career development" />
                    </div>
                </section>

                <section className="py-10 px-6 2xl:px-64 space-y-4">
                    <div className="md:flex h-full gap-x-8">
                        <img className="md:w-1/2 rounded-md" src="https://images.pexels.com/photos/871495/pexels-photo-871495.jpeg?cs=srgb&dl=pexels-godisable-jacob-226636-871495.jpg&fm=jpg" alt="job seekers" />
                        <div className="flex flex-col justify-center gap-y-10 h-100 w-full">
                            <div>
                                <div className="font-bold text-2xl">
                                    <h2>Manpower Services</h2>
                                    <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                                </div>
                                <ul>
                                    <li><b>Talent Search Solution:</b> Assists organizations in identifying and recruiting top talent.</li>
                                    <li><b>Manpower Placement:</b> Connects job seekers with employers who have vacant positions that align with their skills and career goals.</li>
                                    <li><b>Showcase Employment Opportunities:</b> Promotes job openings and career opportunities to attract potential candidates.</li>
                                    <li><b>Manage Recruitments:</b> Oversees and coordinates recruitment activities, including sourcing candidates, screening applicants, conducting interviews, and selecting and onboarding employees.</li>
                                    <li><b>Showcase Gigs and Manage Servicing:</b> Promotes freelance or temporary job opportunities and manages the delivery and completion of those gigs.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-10 px-6 2xl:px-64 space-y-4">
                    <div className="flex h-full gap-x-8">
                        <div className="flex flex-col justify-center gap-y-10 h-100 w-full">
                            <div>
                                <div className="font-bold text-2xl">
                                    <h2>Why Register at Opuvia Career Institute?</h2>
                                    <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                                </div>
                                <ul>
                                    <li><b>Specialized Training:</b> Programs focused on specific industries or professions to equip you with the knowledge and skills required for success.</li>
                                    <li><b>Practical Learning:</b> Emphasizes hands-on experiences with real-world scenarios, case studies, simulations, and industry-specific projects.</li>
                                    <li><b>Industry-Relevant Curriculum:</b> Developed in collaboration with industry experts to ensure courses are up-to-date and aligned with current practices.</li>
                                    <li><b>Networking Opportunities:</b> Opportunities to connect with industry professionals, guest speakers, and fellow students for future job prospects and professional development.</li>
                                    <li><b>Career Services:</b> Supports job search and career development with services like resume building, interview preparation, job placement assistance, and access to job boards and industry contacts.</li>
                                </ul>
                                <p>Register at Opuvia Career Institute and get the best career coaching, committed to nurturing your talent, empowering you, and shaping the workforce of your future.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="h-20"></div>
            </div>
            <Footer />
        </div>
    );
}

export default CareerInstitute;
