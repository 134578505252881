import React from 'react'
import { teamMembers } from '../../data/teams-partners'
import { IonButton } from '@ionic/react'
import TeamMemberDetail from '../../pages/home/TeamMemberDetail'

const OpuviaTeamCard: React.FC<{ team: typeof teamMembers[0] }> = ({ team }) => {
    const [showMemberDetail, setShowMemberDetail] = React.useState(false)
    return (
        <div className='bg-white shadow-xl relative'>
            <div className='relative h-[25rem]'>
                {/* <div className='backdrop-blur-6xl absolute top-0 w-[98%] h-full  z-0' style={{ backgroundImage: `url(${team.image_url})`, backgroundPosition: "50% 0%", backgroundSize: "contain", filter: "blur(5px)" }}>
                                            </div> */}
                <img src={team.image_url} alt="" className='w-full h-[25rem] object-cover backdrop-blur-6xl z-10 absolute top-0 ' />
            </div>
            <div className="p-3 flex justify-between flex-col md:h-[10rem] xl:h-[9rem]">
                <div>
                    <h4 className='font-bold text-xl'>{team.name}</h4>
                    <p className='font-medium text-gray-500 mb-5'>{team.position}</p>
                </div>
                <div>
                    <IonButton onClick={() => setShowMemberDetail(true)} color='dark'>
                        View More
                    </IonButton>
                </div>
            </div>

            <TeamMemberDetail member={team} isOpen={showMemberDetail} onClose={() => setShowMemberDetail(false)} />
        </div>
    )
}

export default OpuviaTeamCard