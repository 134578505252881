import { IonSpinner } from "@ionic/react";
import { useState } from "react";
import { useCareerServices } from "../../hooks/useCareerServices";
import { IInterviewPrep } from "../../interfaces/careerService.interface";
import ConfirmationModal from "../Layout/confirmationPop";



const InterviewPrepApplicationForm = () => {
  /** State management */

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [email, setEmail] = useState("");
  const [salary, setSalary] = useState("");
  const [strengths, setStrengths] = useState("");
  const [interviewType, setInterviewType] = useState("in_person");
  const [mainFears, setMainFears] = useState("");
  const [interviewDuration, setInterviewDuration] = useState("");
  const [formalInterviewAttended, setFormalInterviewAttended] = useState<number>(0);
  const [hideForm, setHideForm] = useState(false)


  const [viewModal, setViewModal] = useState(false);
  const requiredAsterix = <span className="text-red-500">*</span>;


  const { createInterviewPrep, loading: isLoading, error } = useCareerServices()


  async function handleSubmit(e: any) {

    e.preventDefault();
    const form = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phonenumber,
      interview_type: interviewType,
      salary,
      interview_duration: Number(interviewDuration),
      formal_interview_attended: formalInterviewAttended,
      main_fears: mainFears,
      strengths: strengths,

    } as IInterviewPrep;

    const result = await createInterviewPrep(form);

    if (result) {
      setViewModal(true)
      setHideForm(true)
    }



  }


  return (
    <>
      {
        !hideForm && <div className="relative pt-10">
          {viewModal && (
            <ConfirmationModal
              popupTitle={"Application Recieved Successfully"}
              popupDescription={
                "Thank you for submiting the form. This is to acknowledge your application was submitted successfully"
              }
              popupActionButton={[
                <button
                  className="bg-orange-500 text-white p-2 px-5 rounded-md w-full"
                  onClick={() => setViewModal(false)}
                >
                  Ok, Thanks
                </button>,
              ]}
              popupWidth={"400px"}
            />
          )}
          <h2 className="text-xl font-bold mt-4 mb-6">Apply Today</h2>
          <form
            className="grid md:grid-cols-2 gap-5"
            onSubmit={(e) => { handleSubmit(e) }}
          >

            <>
              <div className="">
                <label>First Name {requiredAsterix}</label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="e.g john"
                  className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
                />
              </div>

              <div className="">
                <label>Last Name {requiredAsterix}</label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="e.g doe"
                  className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
                />
              </div>

              <div className="">
                <label> Email {requiredAsterix}</label>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="e.g johndoe@gmail.com"
                  maxLength={255}
                  className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
                />
              </div>

            </>
            <div>
              <div className="md:col-span-2">
                <label> Phone {requiredAsterix}</label>
                <input
                  type="number"
                  value={phonenumber}
                  onChange={(e) => setPhonenumber(e.target.value)}
                  placeholder="e.g 23767100000"
                  className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
                />
              </div>
              <div></div>
            </div>
            <div className="">
              <label>Interview Type {requiredAsterix}</label>
              <select
                // placeholder="e.g johny@example.com"
                value={interviewType}
                onChange={(e) => setInterviewType(e.target.value)}
                required
                className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
              >
                <option className="in_Person">In Person</option>
                <option className="virtual">Online</option>
                <option className="both">In Person and Online</option>
              </select>
            </div>
            <div className="">
              <label>Salary</label>
              <input
                type="text"
                value={salary}
                onChange={(e) => setSalary(e.target.value)}
                placeholder="e.g 1000 XAF"
                required
                className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
              />
            </div>
            <div className="">
              <label>Interview Duration (Hours) {requiredAsterix}</label>
              <input
                type="number"
                value={interviewDuration}
                onChange={(e) => setInterviewDuration(e.target.value)}
                placeholder="e.g 2"
                required
                className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
              />
            </div>
            <div className="">
              <label>
                Number of Formal Interviews you have attended {requiredAsterix}
              </label>
              <input
                type="text"
                placeholder="10"
                value={formalInterviewAttended}
                onChange={(e) => setFormalInterviewAttended(Number(e.target.value || 0))}
                required
                className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
              />
            </div>
            <div className="col-span-2">
              <label>Your Main Fears {requiredAsterix}</label>
              <textarea
                name=""
                id=""
                rows={4}
                value={mainFears}
                onChange={(e) => setMainFears(e.target.value)}
                required
                className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
              ></textarea>
            </div>
            <div className="col-span-2">
              <label>Your Strengths {requiredAsterix}</label>
              <textarea
                name=""
                id=""
                rows={4}
                value={strengths}
                required
                onChange={(e) => setStrengths(e.target.value)}
                className="border-gray-300 block rounded-md p-2 mt-2 w-full border outline-orange-500"
              ></textarea>
            </div>

            {/* <section>
   <div className="relative">
     <div className="mb-2">Upload Your CV {requiredAsterix}</div>
     <div className="w-100 text-orange-400  m-auto border border-orange-300 border-dashed rounded h-32 flex flex-col justify-center items-center">
       <IoCloudUploadOutline className="text-5xl absolute" />
     </div>
   </div>
   <div className="relative">
     <div className="mb-2">Job Advert {requiredAsterix}</div>
     <div className="w-100 text-orange-400  m-auto border border-orange-300 border-dashed rounded h-32 flex flex-col justify-center items-center">
       <IoCloudUploadOutline className="text-5xl absolute" />
     </div>
   </div>
   </section> */}
            <div>
              <button className="bg-orange-500 text-white p-2 px-5 rounded-md w-auto">
                {isLoading ? <IonSpinner /> : "Apply Now"}
              </button>
            </div>
          </form>
        </div>
      }</>
  );
};

export default InterviewPrepApplicationForm;
