import React, { createContext, useEffect, useState } from "react";
import {
  gettingStarted,
  popular,
  programs,
  testimonials,
} from "../../data/programs";
import ClientelleOfferSection from "../../components/Layout/ClientelleOfferSection.component";
import contactLogo from "../../assets/images/chat-icons.png";
import Header from "../../components/Header/Header.component";
import Hero from "../../components/Home/Hero.component";
import Footer from "../../components/Footer/Footer.component";
import { Link } from "react-router-dom";
import StudyAtDreamUniversityWidget from "../../components/StudyAtDreamUniveristyWidget/StudyAtDreamUniversityWidget";
import { partners } from "../../data/teams-partners";
import TeamMemberDetail from "./TeamMemberDetail";
import { localContact } from "../../hooks/useContacts";
import Title from "../../components/Header/Title.component";
import { Event, useEvents } from "../../hooks/useEventHooks";
import { parseDate } from "../../api/utilities";
import EventsCard from "../../components/Home/EventsCard";
import { IonSpinner } from "@ionic/react";
import { Provider } from "react-redux";


export const EventsContext = createContext<Event[]>([])

const Home = () => {
  const [scrolled, setScrolled] = useState(false);

  const contact = localContact()
  const { events, loading } = useEvents()
  const upcomingEvents = events.filter((event, index) => {
    return (parseDate(event.start_date)?.type == "upcoming")
  });



  useEffect(() => {
    const handleScroll = () => {
      console.log(window.pageYOffset)
      if (window.pageYOffset > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="relative ">
      <Title title='Home | OPUVIA' description=' Welcome to Opuvia, a company on a mission to close the gap between
            education and employment and curb the global problem of graduate
            under and unemployment.' />
      <Header scrolled={scrolled} />
      <EventsContext.Provider value={upcomingEvents} >
        <Hero />
      </EventsContext.Provider>

      <div className=" text-black bg-blue relative">
        <section className="py-10 px-6 2xl:px-60">
          <div className="font-bold text-2xl">
            <h2>ABOUT OPUVIA</h2>
            <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
          </div>
          <p className="mb-2">
            Welcome to Opuvia, a company on a mission to close the gap between
            education and employment and curb the global problem of graduate
            under and unemployment. We believe that every person deserves an
            opportunity to succeed and thrive in their chosen career, and we are
            committed to providing the tools and resources necessary to make
            that a reality.
          </p>
          <p>
            At Opuvia, we understand that the problem of graduate under and
            unemployment is complex and multifaceted, with many underlying
            factors contributing to the issue. That's why we take a holistic
            approach to our work, addressing not just the immediate needs of job
            seekers and employers, but also the systemic barriers that prevent
            individuals from accessing the right opportunities.
          </p>
        </section>
        <section id="training" className=" bg-gray-50 py-10 px-6 2xl:px-60">
          <div className="font-bold text-2xl uppercase">
            <h2>Opuvia Programs</h2>
            <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
          </div>
          <div className=" block   md:grid md:grid-cols-2 lg:grid-cols-4 gap-3">
            {programs.map((program, index) => {
              return (
                <div
                  key={index}
                  className="mb-10 bg-white rounded-md border-gray-200 border"
                >
                  <img
                    className="h-[300px] rounded-t-md w-full object-cover"
                    src={program.image}
                    alt={program.title}
                  />
                  <div className="p-3 pb-4 ">
                    <h2 className="text-2xl font-bold my-2">{program.title}</h2>
                    <div className="md: justify-between  md:flex flex-col">
                      <p className="text-gray-600 text-sm">
                        {/* {program.description.length > 325
                          ? program.description.substring(0, 330) + "..."
                          : program.description} */}
                        {program.description}
                      </p>
                      {/* <button className="w-[100px] p-1 rounded-md mt-3 text-white bg-opuviaBlue-500">
                        Read More
                      </button> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>

        <section className="py-10 px-6 2xl:px-60">
          <StudyAtDreamUniversityWidget
            url={"/uni-placement"}
          />
        </section>
        <section className="py-10 px-6 2xl:px-60">
          <div className="font-bold text-2xl uppercase">
            <h2>Popular Programs</h2>
            <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
          </div>

          <div className=" md:grid md:grid-cols-2 lg:grid-cols-4 gap-7">
            {popular.map((program, index) => {
              return (
                <div
                  key={index}
                  className="rounded-md mb-10 bg-white border-gray-200 border relative"
                >
                  <img
                    className="h-[300px] rounded-t-md w-full object-cover"
                    src={program.image}
                    alt={program.title}
                  />
                  <div className="p-3 h-[100px] w-full absolute bottom-0 rounded-b-md text-white bg-gradient-to-t from-[#0000009e] to-transparent">
                    <h2 className="text-xl font-semibold mt-10">
                      {program.title}
                    </h2>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        <section id="clientele">
          <ClientelleOfferSection />
        </section>
        <section className="p-24 px-6 2xl:px-60 -mt-8 lg:mt-20">
          <div className="p-8 shadow rounded-2xl text-white bg-gradient-to-l to-orange-300 from-orange-500 grid-cols-2 grid">
            <div>
              <h3 className="text-xl font-bold ">Help & Support</h3>
              {/* <p>Typical reply within 5 minutes</p> */}
              <a
                href={`https://api.whatsapp.com/send?phone=${"237672624892"}`}
                target="__blank"
              >
                <button className="p-1 px-3 mt-3 rounded-full text-sm bg-white text-black">
                  Contact Support
                </button>
              </a>
            </div>
            <div>
              <img className="w-[200px] float-right" src={contactLogo} />
            </div>
          </div>
        </section>
        <section className="py-10 px-6 2xl:px-60 bg-gray-50">
          <div className="font-bold text-2xl uppercase">
            <h2>Testimonials</h2>
            <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
          </div>
          <div className="md:grid grid-cols-2 lg:grid-cols-3 gap-8">
            {testimonials.map((person, i) => {
              return (
                <div
                  key={i}
                  className="shadow p-6 bg-white   text- rounded-lg text-center mt-4"
                >
                  <p className="text-sm mt-6 text-gray-600 2xl:h-[180px]">{person.message}</p>
                  <div className="flex flex-col justify-end items-center">
                    {person.image && <img
                      className="h-20 w-20 inline-block rounded-full mt-6 object-cover"
                      src={person.image}
                      alt={person.name}
                    />}
                    <div className="font-semibold text-enter">{person.name}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>



        <section className="py-10 px-6 2xl:px-60">
          <div className="font-bold text-2xl uppercase">
            <h2>GETTING STARTED WITH OPUVIA</h2>
            <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
          </div>
          <div className="md:grid grid-cols-2 lg:grid-cols-4 gap-8">
            {gettingStarted.map((info, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col px-4  justify-around min-h-[30vh] p-4 border pb-8 border-gray-200 rounded-sm text-center mt-4"
                >
                  <div>
                    <img
                      className="h-20 w-20 inline-block object-cover rounded-full mb-6"
                      src={info.image}
                      alt=""
                    />
                  </div>
                  <p className="text-sm">{info.description}</p>
                  <Link to="https://app.opuvia.org/signup">
                    <button className="mt-10 p-2 font-bold  bg-orange-500 text-sm text-white px-3 rounded-md">
                      {info.buttonText}
                    </button>
                  </Link>
                </div>
              );
            })}
          </div>
        </section>
        <div className="h-20"></div>

        <section className="py-10 px-6 2xl:px-60">
          <div className="font-bold text-2xl uppercase">
            <h2>OUR PARTNERS</h2>
            <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
          </div>
          <div className="md:flex gap-x-20 items-center space-y-4">
            {partners.map((info, index) => {
              return (
                <div>
                  <a href={info.link || ""} target="_blank" rel="noreferrer" key={index}>
                    <img className="w-1/2 m-auto md:w-[40em]" src={info.logo_url} alt={info.name} />
                  </a>
                </div>
              );
            })}
          </div>
        </section>
        {upcomingEvents.length > 0 && <section className="py-10 px-6 2xl:px-60 bg-gray-50">
          <div className="font-bold text-2xl uppercase">
            <h2>Upcoming Events</h2>
            <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
          </div>

          <div className="">
            <div className="grid lg:grid-cols-2 gap-5 h-full"> {
              upcomingEvents.filter((event, i) => (i < 4)).map((event, index) => {
                return (
                  <EventsCard event={event!} key={index} />
                )
              })
            }
              {loading && <IonSpinner color={'warning'} />}
            </div>
          </div>


        </section>}
        <div className="h-20"></div>

      </div>
      <Footer />
    </div>
  );
};

export default Home;
