


export const fairActivities = [
    {
        title: "Career workshops (Students, graduates, parents and job seekers",
        image: "https://careerfoundry.com/en/wp-content/uploads/2021/07/A_good_coding_bootcamp_teaches_you_not_just_to_learn_code_but_how_to_talk_about_it.jpg",
        description: ``,
        url: ''
    },
    {
        title: "One-on-one career consultations",
        image: "https://bristolcreativeindustries.com/app/uploads/2021/02/Black-Codher.png",
        description: ``,
        url: ''
    },
    {
        title: "Exhibition (One-on-one with universities and Company representatives)",
        image: "https://media.istockphoto.com/id/1311107708/photo/focused-cute-stylish-african-american-female-student-with-afro-dreadlocks-studying-remotely.jpg?b=1&s=612x612&w=0&k=20&c=fpQZIyrCZorMYI4DoEP6opgP0IzXnlEFVlK3lOWIWEM=",
        description: ``,
        url: ''
    },
    {
        title: "LinkedIn photo shoot",
        image: "https://images.pexels.com/photos/5905713/pexels-photo-5905713.jpeg?cs=srgb&dl=pexels-katerina-holmes-5905713.jpg&fm=jpg",
        description: ``,
        url: ""
    }

]