//app slice for modals and loading
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
    showProfileModal: false,
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setShowProfileModal: (state, action: PayloadAction<boolean>) => {
            state.showProfileModal = action.payload;
        }
    }
});

export const { setShowProfileModal } = appSlice.actions;

export const selectShowProfileModal = (state: RootState) => state.app.showProfileModal;

export default appSlice.reducer;