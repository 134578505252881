import { configureStore } from '@reduxjs/toolkit';
import appReducer from './reducers/appSlice';

const store = configureStore({
    reducer: {
        app: appReducer,
        // Add other reducers here if needed
    },
});
//RootState
export type RootState = ReturnType<typeof store.getState>;

export default store;