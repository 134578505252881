import { useIonToast } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { IContact } from "../interfaces/contact.interface";
import { handleError } from "../api/utilities";
import { useEffect, useState } from "react";
import axiosInstance from "../api/axios";



export function localContact() {
    const contactStr = localStorage.getItem("contact")
    const contact = contactStr ? JSON.parse(contactStr) as IContact : undefined
    return contact;
}

export const useContacts = () => {

    const [toast] = useIonToast();
    const contct = localContact()
    const [contact, setContact] = useState<IContact | undefined>(contct)


    async function getContacts() {
        return await axiosInstance
            .get("/contacts")
            .then((response) => {
                setContact(response.data.data);
                localStorage.setItem("contact", JSON.stringify(response.data.data));
                return response.data.data
            })
            .catch((err) => console.log(err))
    }



    async function createContact(contactInfo: IContact) {
        return await axiosInstance
            .post(
                "/contacts",
                contactInfo,
            )
            .then((response) => {
                toast({
                    message: "Contact created successfully!",
                    duration: 3000,
                    color: "success",
                    buttons: ["OK"],
                });
                return response.data;
            })
            .catch((err) => handleError(err, toast))
    }

    async function updateContact(id: number, contact: Partial<IContact>) {
        return await axiosInstance
            .put(
                `/contacts/`,
                contact,

            )
            .then((response) => {
                toast({
                    message: "Contact updated successfully",
                    duration: 3000,
                    color: "success",
                    buttons: ["OK"],
                });

                setContact(response.data.data)
                return response.data;
            })
            .catch((err) => handleError(err, toast))
    }

    async function deleteContact(contactId: number) {
        return await axiosInstance
            .delete(
                `/contacts/`,
            )
            .then((response) => {
                toast({
                    message: "Deleted Successfully",
                    duration: 3000,
                    color: "success",
                });
                return response.data;
            })
            .catch((error) => {
                toast({
                    message: "Error deleting contact",
                    duration: 3000,
                    color: "danger",
                });
                return null;
            });
    }

    useEffect(() => {
        getContacts();
    }, [])

    return {
        createContact,
        getContacts,
        updateContact,
        deleteContact,
        contact
    };
};
