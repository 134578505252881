import { useEffect, useState } from 'react'
import Footer from '../../components/Footer/Footer.component';
import Header from '../../components/Header/Header.component';

const PartnerWithUs = () => {
    const [scrolled, setScrolled] = useState(false);
    const [showMemberDetail, setShowMemberDetail] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 200) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);



    return (

        <div className="relative ">
            <Header scrolled={scrolled} />
            {/* <HeroAbout /> */}

            <div className=" text-black bg-blue relative mt-24">
                <section className="py-10 px-6 2xl:px-60">
                    <div className="font-bold text-2xl">
                        <h2>Partner With Us</h2>
                        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                    </div>
                    <p className="mb-2 md:flex" >
                        <div className='space-y-4 '>
                            <div>
                                Are you an educational institution, coach, trainer, or industry expert dedicated to nurturing talent and shaping successful careers?
                            </div>
                            <p>
                                Opuvia Ltd invites you to embark on a transformative partnership with us. Together, we can empower individuals, bridge the gap between education and employment, and foster a thriving ecosystem of career and professional excellence.
                            </p>
                        </div>
                    </p>

                </section>
                <section id="training" className=" bg-gray-50 py-10 px-6 2xl:px-60 space-y-4">
                    <div className="font-bold text-2xl uppercase">
                        <h2>Why Partner with Us?</h2>
                        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                    </div>
                    <div className="">
                        <div className=" block  gap-y-8  md:grid md:grid-cols-2 lg:grid-cols-3 gap-3 mt-5">

                            <div>
                                <div className="flex gap-2 items-center font-bold text-xl mb-2">
                                    <div className="w-4 h-4 bg-orange-500"></div> <h1>
                                        Comprehensive Career Solutions:
                                    </h1>
                                </div>
                                <p>
                                    As a leading career and job placement company, Opuvia offers a comprehensive suite of career solutions designed to meet the diverse needs of individuals. By partnering with us, you gain access to our expertise in career counselling, job placement, resume building, interview coaching, and more. We provide the tools and support necessary to guide individuals towards meaningful career paths and successful job placements.
                                </p>

                            </div>
                            <div>
                                <div className="flex gap-2 items-center font-bold text-xl mb-2">
                                    <div className="w-4 h-4 bg-orange-500"></div> <h1>
                                        Collaboration Opportunity:
                                    </h1>
                                </div>
                                <p>
                                    Collaboration is at the heart of our partnership philosophy. We believe in working closely with our partners to create synergistic initiatives that drive mutual success. By joining forces, we can leverage each other's strengths, share resources, and co-create innovative programs, workshops, and events that foster career development and enhance the employability of individuals.
                                </p>

                            </div>
                            <div>
                                <div className="flex gap-2 items-center font-bold text-xl mb-2">
                                    <div className="w-4 h-4 bg-orange-500"></div> <h1>
                                        Industry Insights and Trends
                                    </h1>
                                </div>
                                <p>Stay ahead of the curve with our industry insights and trends. At Opuvia, we stay abreast of evolving market dynamics, emerging careers, and in-demand skills. By partnering with us, you gain access to this valuable knowledge, enabling you to provide up-to-date guidance and ensure that your students, clients, or employees are equipped with the skills and knowledge needed to thrive in the job market.
                                </p>
                            </div>
                            <div>
                                <div className="flex gap-2 items-center font-bold text-xl mb-2">
                                    <div className="w-4 h-4 bg-orange-500"></div> <h1>
                                        Brand Exposure and Promotion:
                                    </h1>
                                </div>
                                <p>Partnering with Opuvia offers exceptional brand exposure and promotion opportunities. We showcase our partners' expertise, services, and success stories through our marketing channels, social media platforms, and industry events. This exposure enhances your brand visibility and reputation within the career and job placement landscape, positioning you as a leader in your field. </p>
                            </div>
                            <div>
                                <div className="flex gap-2 items-center font-bold text-xl mb-2">
                                    <div className="w-4 h-4 bg-orange-500"></div> <h1>
                                        Shared Success and Impact:
                                    </h1>
                                </div>
                                <p>By partnering with us, you become part of a community dedicated to making a lasting impact on individuals' professional lives. We celebrate shared success, collaborate on transformative initiatives, and share in the joy of empowering individuals to unlock their full potential. Together, we can shape a brighter future and build a workforce that drives innovation and growth.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>




                <section className="py-10 px-6 2xl:px-60">
                    <div className="font-bold text-2xl uppercase">
                        <h2>Partner with Opuvia</h2>
                        <div className="w-[30px] bg-orange-500 h-1 mb-5"></div>
                    </div>

                    <section className="py-3 space-y-3">
                        <p>
                            Join us in our mission to transform careers, bridge the gap between education and employment, and create a thriving ecosystem of career and job placement excellence.
                        </p>
                        <p>
                            Partner with Opuvia today and unleash the power of collaboration, innovation, and impact.
                        </p>
                        <p>
                            To explore partnership opportunities or learn more about how we can collaborate, please contact us at <a href="tel:tel:+237672624892" target="_blank" rel="noopener noreferrer" className="link">(+237) 672624892</a>/ <a href="tel:+237683165278" target="_blank" rel="noopener noreferrer" className="link">683165278</a> or check our website at www.opuvia.org – (Link to form if available)
                            Let's embark on a transformative journey together and empower individuals to achieve their career dreams! </p>
                    </section>
                </section>


            </div >
            <Footer />
        </div >
    )
}

export default PartnerWithUs