import { ToastOptions } from "@ionic/react";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";


export class UtilityService {
  static ParseHeadings(text: string): JSX.Element[] {
    const lines = text.split('\n');
    const headings: JSX.Element[] = [];

    for (const line of lines) {
      const colonIndex = line.indexOf(':');
      if (colonIndex !== -1) {
        const header = line.substring(0, colonIndex).trim();
        const description = line.substring(colonIndex + 1).trim();
        headings.push(
          <div className="mb-2" key={header}>
            <h2 className="font-bold">{header}</h2>
            <p>{description}</p>
          </div>
        );
      }
    }

    return headings;
  }
}

export function decodeErrorResponse(data: string) {
  const regex = /\["([^"]+)"\]/g;
  const text = data
  const matches = text.match(regex);
  const result = matches?.map((match: string) => match.slice(2, -2)).join(', ');
  return result
}

export function handleError(err: any, toast: {
  (message: string, duration?: number | undefined): Promise<void>;
  (options: ToastOptions & HookOverlayOptions): Promise<void>;
}) {
  console.log(err)
  toast({ message: err.response?.data.message || "An Error occured. Please try again later!", color: "danger", duration: 4000 })
}

export function parseDate(dateStr: string): {
  month: { name: string; shortName: string; color: string };
  day: string;
  year: string;
  time: string;
  timeago: string;
  type: "past" | "upcoming";
} | null {
  try {



    const dateObj = new Date(dateStr.split("T")[0]);

    if (isNaN(dateObj.getTime())) {
      return null;
    }

    const now = new Date();
    const delta = dateObj.getTime() - now.getTime();
    const timeAgo = getTimeAgo(delta);

    return {
      month: getMonthInfo(Number(dateObj.toLocaleString("en-US", { month: "2-digit" })))!,
      day: dateObj.toLocaleString("en-US", { day: "2-digit" }),
      year: dateObj.getFullYear().toString(),
      time: dateObj.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      }),
      timeago: timeAgo,
      type: delta < 0 ? "past" : "upcoming",
    };
  } catch (error) {
    return null;
  }
}


function getTimeAgo(delta: number) {
  const seconds = Math.abs(delta / 1000);

  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return `${years}y`;
  } else if (weeks > 0) {
    return `${weeks}w`;
  } else if (days > 0) {
    return `${days}d`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else {
    return `${minutes}m`;
  }
}

export function getMonthInfo(monthNumber: number): { name: string; shortName: string; color: string } | null {
  const months = {
    1: { name: "January", shortName: "Jan", color: "#efef44" },
    2: { name: "February", shortName: "Feb", color: "#3498db" },
    3: { name: "March", shortName: "Mar", color: "#e74c3c" },
    4: { name: "April", shortName: "Apr", color: "#2ecc71" },
    5: { name: "May", shortName: "May", color: "#9b59b6" },
    6: { name: "June", shortName: "Jun", color: "#f39c12" },
    7: { name: "July", shortName: "Jul", color: "#d35400" },
    8: { name: "August", shortName: "Aug", color: "#2980b9" },
    9: { name: "September", shortName: "Sep", color: "#16a085" },
    10: { name: "October", shortName: "Oct", color: "#27ae60" },
    11: { name: "November", shortName: "Nov", color: "#f1c40f" },
    12: { name: "December", shortName: "Dec", color: "#c02942" },
  };

  if (monthNumber < 1 || monthNumber > 12) {
    return null;
  }

  return months[monthNumber as 1];
}


export function checkLoginDate() {
  const loginDate = localStorage.getItem("loginDate");
  if (loginDate) {
    const loginDateObj = new Date(loginDate);
    const now = new Date();
    const diffTime = now.getTime() - loginDateObj.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 50) {
      localStorage.clear();
      window.location.replace("/");
    }
  }
}
